import wofIT from "@/assets/languages/italian/worldOfMusic";
import createVoucherIT from "@/assets/languages/italian/createVoucher";
import tmfIT from "@/assets/languages/italian/theMagicFlute";

const italian = {
    title: 'Classplash – Portale clienti',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Accedi al nostro portale per continuare',
        login: 'Accedi',
        noAccount: 'Ancora nessun account?',
        email: 'E-mail',
        password: 'Password',
        passwordConfirm: 'Conferma la password',
        type: 'Gruppo di utenti',
        newsletter: 'Newsletter',
        newsletterCheckbox: 'Vorrei essere informato sui nuovi prodotti e sugli aggiornamenti.',
        agb: 'Ho letto e accettato i ' +
            '<a href="https://www.classplash.de/termsofuse" target="_blank">Termini e le Condizioni</a> ' +
            `e <a href="https://www.classplash.de/privacypolicy" target="_blank">"l’Accordo sulla privacy"</a>`,
        signup: 'Registrati',
        createAccount: 'Crea il tuo account gratuito su Classplash',
        logout: 'Esci',
        oldPW: 'Vecchia password',
        saveData: 'Salva',
        firstname: 'Nome',
        lastname: 'Cognome',
        name: 'Nome',
        invalid: {
            email: 'Si prega di immettere un’e-mail valida.',
            pwConfirm: `Le password non corrispondono`,
            pwNotSecure: 'La tua password non è abbastanza sicura. Deve contenere almeno un numero, una lettera minucola e una lettera maiuscola. La password deve esssere lunga almeno 6 caratteri.'
        },
        admin: 'Amministratore',
        forgot: 'Password dimenticata?',
        reset: 'Ripristina la password',
        redeemVoucher: 'Riscattare il buono/voucher?',
        videoTutorial: 'Video tutorial',
        request: 'Invia richiesta',
        resetSuccess: 'Password ripristinata con successo',
        resetFail: 'Impossibile ripristinare la password',
        newPW: 'Nuova password',
        failed: `Accesso fallito. Controlla se l'e-mail e la password sono corrette.`,
        signupFailed: {
            general: 'Registrazione fallita. Controlla se tutte le informazioni richieste sono state compilate',
            passwordNotSecure: 'Registrazione fallita. La tua password non è abbastanza sicura. Deve contenere almeno un numero, una lettera minuscola e una lettera maiuscola. La password deve essere lunga almeno 6 caratteri.',
            emailAlreadyExists: 'Registrazione fallita: l\'indirizzo e-mail è già stato inserito. Si prega di accedere con l’account esistente o utilizzare un altro indirizzo e-mail. Se l\'errore persiste, visitare le nostre FAQ www.classplash.de/faq o contattare direttamente support@classplash.com.'
        },
        acceptAGB: 'Si prega di accettare i "Terms of use" e "Privacy Policy" prima di procedere',
        verificationSent: 'Si prega di confermare l‘-email per completare la registrazione.',
        haveAccount: 'Ho un account!',
        checkSpam: 'Controlla la tua cartella spam se non hai ricevuto l\'email di conferma!'
    },
    user: {
        profileTitle: 'Il tuo account Classplash',
        name: 'Nome',
        subscribe: 'Iscriviti',
        unsubscribe: 'Clicca qui per disiscriverti dalla newsletter',
        newsletterState: 'Stato della newsletter',
        notVerified: 'La tua email non è ancora stata verificata. Si prega di verificare l’e-mail il prima possibile.',
        verified: 'Verificata',
        pendingEmail: 'In attesa dell’e-mail'
    },
    button: {
        edit: 'Modifica',
        delete: 'Elimina',
        create: 'Crea {objectName}',
        upload: 'Carica voucher',
        userManagement: 'Amministrazione dell‘utente',
        productManagement: 'Gestione del prodotto',
        cancel: 'Annulla',
        close: 'Chiudi',
        save: 'Salva',
        add: '{objectName} aggiungi',
        saved: 'Modifiche salvate',
        created: '{objectName} creato',
        saveFailed: 'Non è stato possibile salvare le modifiche',
        createFailed: 'Impossibile creare {objectName}. {additionalInfo}',
        deleted: '{objectName} eliminato',
        more: 'Più',
        requestSent: 'Richiesta inviata',
        resendVerification: 'E-Mail di conferma inviata di nuovo',
        next: 'Avanti',
        verifyEmail: 'Si prega di verificare l’e-mail prima di procedere.',
        resendReceipt: 'Inviare nuovamente la ricevuta',
        checkDelete: 'Vuoi davvero cancellare questo elemento?',
        help: 'Aiuto',
        downloadAgain: 'Scarica di nuovo',
        back: 'Indietro',
        associate: 'Applicare un prodotto'
    },
    modal: {
        close: 'Chiudi',
        receipt: 'Mostra la ricevuta',
        unsubscribe: '<span style="font-size: larger;">Abbiamo notato che hai cancellato la tua iscrizione alla newsletter <br> Puoi dirci perché?</span>',
        submit: 'Invia',
        id: '{objectName} ID',
        name: '{objectName} Name',
        variant: 'Varianti di prodotto',
        variantPrice: 'Variante dei costi',
        variantDuration: 'Variante della durata',
        variantSourceID: 'Origini delle varianti ID',
        shopifySent: 'Dovresti ricevere una mail nei prossimi minuti. Includerà un link con il quale potrai gestire il tuo abbonamento',
        variantUses: 'Varianti d\'uso'
    },
    license: {
        licenses: 'Licenze',
        licensesNotFound: 'Nessuna licenza trovata',
        currentSubscription: 'Abbonamenti correnti',
        expiredSubscription: 'Abbonamenti scaduti',
        name: 'Nome',
        buy: 'Data di acquisto',
        expire: 'Data di scadenza',
        method: 'Metodo',
        feature: 'Feature',
        show: 'Mostra licenza',
        productID: 'Nome prodotto',
        variantID: 'Nome variante',
        paymentSource: 'Origine di pagamento',
        startSub: 'Inizio abbonamento',
        endSub: 'Fine abbonamento',
        isActive: 'Momentaneamente attivo',
        autoRenew: 'Aggiornamento automatico',
        reSubscribe: 'Iscriviti di nuovo',
        lifeTime: 'Non ha scadenza',
        redirectShop: 'Visualizza i prodotti',
        key: 'Chiave',
        appDownload: 'Download App',
        eBookDownload: 'Download E-Book',
        manageSubscription: 'Gestisci abbonamento'
    },
    session: {
        activeSessions: 'Sessioni attive',
        source: 'Origine',
        firstDate: 'Primo accesso',
        lastUse: 'Ultimo utilizzo',
        destroy: 'Sessione conclusa'
    },
    email: {
        validationTrying: 'Stiamo cercando di convalidare la tua e-mail',
        validationError: 'Purtroppo non siamo riusciti a verificare la tua email.',
        validationSuccess: 'E-mail convalidata',
        notValidated: `L'indirizzo e - mailnon èverificato. Perfavore, verifica iltuo indirizzoe-mailtramite l'e-mail che hai ricevuto.`
    },
    language: {
        language: 'Lingua',
        german: 'Tedesco',
        english: 'Inglese',
        portuguese: 'Portoghese',
        danish: 'Danese',
        spanish: 'Spagnolo',
        french: 'Francese',
        italian: 'Italiano',
        croatian: 'Croato',
        hungarian: 'Ungherese',
        dutch: 'Olandese',
        portugueseBR: 'Portoghese (Brasile)',
        russian: 'Russo',
        slovenian: 'Sloveno',
        chinese: 'Cinese'
    },
    select: {
        noLike: `Non desidero nessuna newsletter`,
        noCare: `Non mi interessa`,
        noReason: 'Nessun motivo particolare/Non lo voglio dire'
    },
    voucher: {
        voucher: 'Voucher',
        key: 'Chiave',
        redeem: 'Riscatta il buono',
        uploaded: 'Carica il voucher',
        uploadFailed: 'Non è stato possibile caricare i voucher',
        redeemText: 'Hai un buono per uno dei nostri pacchetti? Allora puoi riscattarlo proprio qui.',
        redeemNow: 'Riscatta ora',
        enterCode: 'Si prega di inserire il codice del tuo buono per il tuo pacchetto. Questo ti dà accesso all\'app. Si prega di prestare attenzione alle lettere maiuscole e minuscole.',
        referralCode: 'Codice del buono',
        associate: 'Applicare il coupon alla mail {user}?',
        noAppKey: 'Nessuna chiave trovata. Cerca di nuovo la tua chiave tra le licenze.'
    },
    bundle: {
        title: 'Procurati uno strumento e un’App per la musica',
        text: 'Nessun buono da riscattare e ancora alla ricerca di uno strumento? Allora controlla i nostri esclusivi bundle di strumenti.',
        button: 'Mostrami i bundle'
    },
    types: {
        none: 'Nessuna informazione',
        teacher: 'Insegnante',
        student: 'Studente',
        regular: 'Normale',
        school: 'Scuola',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Chiave prodotto',
        product: 'Prodotto',
        user: 'Utente',
        shop: 'Shop',
        false: 'No',
        true: 'Sì'
    },
    toast: {
        failedRequest: '{requestGoal} fallito. {addInfo}',
        successRequest: '{requestGoal} con successo',
        invalidInput: 'Le voci non sono corrette. Controllare se tutte le informazioni richieste sono state compilate correttamente',
        notSent: 'Impossibile inviare la richiesta',
        noIdFound: 'Nessun ID trovato',
        error: 'Errore'
    },
    request: {
        redeemVoucher: 'Riscatta il buono',
        pwReset: 'Invio e-mail di ripristino',
        resetPWValue: 'Ripristina la password',
        deleteUser: 'Elimina l‘utente',
        createUser: 'Crea un utente',
        saveUser: 'Cambia le modifiche all’utente',
        deleteProduct: 'Elimina prodotto',
        createProduct: 'Crea prodotto',
        saveProduct: 'Cambia le modifiche al prodotto',
        resendVerification: 'Invia di nuovo l’e-mail di conferma',
        sendManagementLink: 'Invia link di gestione'
    },
    addInfo: {
        contactSupport: 'Si prega di contattare support@classplash.com per assistenza.'
    },
    welcome: {
        step: '{nr}. Passo',
        signup: `Non ho ancora un account`,
        login: 'Ho già un account',
        hello: '<span style="font-size: 35px;">Ciao.<br/>Benvenuto nel mondo della musica</span>',
        weGuide: `<span style="font-size: 22px;"> Vi guideremo passo dopo passo attraverso l'attivazione delvostro prodotto.<br/>BastacliccaresuAvanti.</span>`,
        selectBundle: 'Scegli il tuo pacchetto',
        bundleMissing: `Non è presente?<br/>Contattaci.`,
        createAccount: 'Crea il tuo account Classplash',
        loginAccount: 'Registrati sul nostro portale.',
        great: 'Fantastico!',
        install: 'Scarica l\'applicazione gratuita e installala sul tuo dispositivo.',
        language: 'Scegli la lingua nell‘app.\n',
        selectLogin: 'Seleziona questa icona e poi clicca su Log in.',
        selectLicense: 'Seleziona: "Ho una licenza..."',
        doLogin: 'Accedi con il tuo account Classplash',
        insertLicense: 'Inserisci questa licenza nell\'app. Ora l\'app è completamente attivata! \n',
        note: 'Nota: puoi sempre trovare le tue licenze nel nostro portale',
        howTo: 'Come utilizzi le nostre App?',
        eBook: 'Desideri un fantastico e-book?',
        tip: 'Suggerimento importante:',
        tipExp: `Non dimenticare di accordare il tuo ukulele più volte, perché le corde perdono la loro tensione all'inizio. è perfettamente normale, dato che prima devono essere allungate un po' di volte. Buon divertimento!`,
        done: 'Fatto! Sei pronto! Buon divertimento!',
        video: 'Videotutorial',
        videoDesc: 'Abbiamo creato un breve video che spiega separatamente i singoli passi.',
        videoSrc: 'https://www.youtube.com/embed/bSg6HC7jocY?rel=0',
        createAccountDesc: 'Per ricevere una licenza per la vostra applicazione o per accedere alle applicazioni classplash, devi prima creare un account e poi riscattare il tuo voucher. Se hai già un account, clicca su "Ho un account".',
        faq: `<div>In caso di problemi con l'utilizzo dell'app, visitare le nostre FAQ <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a>
        o contattare direttamente <a href="mailto:support@classplash.de">support@classplash.com</a>.</div>`,
        license: 'La tua licenza',
        linkHowTo: 'PL7nCql3jN6U5hwT3GzrJKmaL7qkerXsUw'
    },
    error: {
        invalid: 'Voucher non valido',
        redeemed: 'Il voucher è già stato riscattato.',
        duplicate: 'Questo utente ha già una licenza per questo prodotto.',
        contact: `Se l'errore persiste, visitare le nostre FAQ <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a>
        o contattare direttamente <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a>.`
    },
    search: 'Ricerca',
    wom: wofIT,
    tmf: tmfIT,
    createVoucher: createVoucherIT
}

export default italian;
