import wofDE from "@/assets/languages/german/worldOfMusic";
import createVoucherDE from "@/assets/languages/german/createVoucher";
import tmfDE from "@/assets/languages/german/theMagicFlute";

const german = {
    title: 'Classplash - Kundenportal',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Melde dich auf unserem Portal an um fortzufahren',
        login: 'Anmelden',
        noAccount: 'Noch kein Konto?',
        email: 'E-mail',
        password: 'Passwort',
        passwordConfirm: 'Passwort bestätigen',
        type: 'Benutzergruppe',
        newsletter: 'Newsletter',
        newsletterCheckbox: 'Ich möchte über neue Produkte und Updates informiert werden.',
        agb: 'Ich habe die <a href="https://www.classplash.de/termsofuse" target="_blank">"AGB"</a> ' +
            'und <a href="https://www.classplash.de/privacypolicy" target="_blank">"Datenschutzvereinbarung"</a> ' +
            'gelesen und verstanden',
        signup: 'Registrieren',
        createAccount: 'Erstelle dein kostenloses Classplash-Konto',
        logout: 'Abmelden',
        oldPW: 'altes Passwort',
        saveData: 'Speichern',
        firstname: 'Vorname',
        lastname: 'Nachname',
        name: 'Name',
        invalid: {
            email: 'Biite gib eine gültige Email an.',
            pwConfirm: `Passwörter stimmen nicht überein`,
            pwNotSecure: 'Dein Passwort ist nicht sicher genug. Es müssen mindestens eine Zahl, ein Klein- und ein Großbuchstabe enthalten sein. Das Passwort muss mindestens 6 Zeichen lang sein.'
        },
        admin: 'Administrator',
        forgot: 'Passwort vergessen?',
        reset: 'Passwort zurücksetzen',
        redeemVoucher: 'Gutschein/Voucher einlösen?',
        videoTutorial: 'Video Tutorial',
        request: 'Anfrage senden',
        resetSuccess: 'Passwort erfolgreich zurückgesetzt',
        resetFail: 'Konnte Passwort nicht zurücksetzen',
        newPW: 'Neues Passwort',
        failed: 'Anmeldung fehlgeschlagen. Prüfe ob Email und Passwort korrekt sind.',
        signupFailed: {
            general: 'Registrierung fehlgeschlagen. Prüfe ob alle erforderlichen Angaben korrekt ausgefüllt sind',
            passwordNotSecure: 'Registrierung fehlgeschlagen. Dein Passwort ist nicht sicher genug. Es müssen mindestens eine Zahl, ein Klein- und ein Großbuchstabe enthalten sein. Das Passwort muss mindestens 6 Zeichen lang sein.',
            emailAlreadyExists: 'Registrierung fehlgeschlagen: E-Mail-Adresse bereits hinterlegt. Bitte mit bestehendem Account einloggen oder andere Mail Adresse benutzten. Sollte der Fehler weiterhin bestehen, besuche bitte unsere FAQ www.classplash.de/faq oder wende dich direkt an support@classplash.com.'
        },
        acceptAGB: 'Bitte aktzeptiere die "Terms of use" und "Privacy Policy" bevor du weitermachst',
        verificationSent: 'Bitte Registrierungsmail bestätigen um Registrierung zu beenden.',
        haveAccount: 'Ich habe ein Konto!',
        checkSpam: 'Überprüfe deine Spam-Ordner falls du kein Registrierungsmail erhalten hast!'
    },
    user: {
        profileTitle: 'Dein Classplash Account',
        name: 'Name',
        subscribe: 'Abonnieren',
        unsubscribe: 'Klicke hier um dich vom Newsletter abzumelden',
        newsletterState: 'Newsletter Status',
        notVerified: 'Deine E-Mail ist noch nicht verifiziert. Bitte verifiziere diese sobald wie möglich.',
        verified: 'Verifiziert',
        pendingEmail: 'Ausstehende Email'
    },
    button: {
        edit: 'Bearbeiten',
        delete: 'Löschen',
        create: 'Erstelle {objectName}',
        upload: 'Voucher hochladen',
        userManagement: 'Benutzerverwaltung',
        productManagement: 'Produkteverwaltung',
        cancel: 'Abbrechen',
        close: 'Schliessen',
        save: 'Speichern',
        add: '{objectName} hinzufügen',
        saved: 'Änderungen gespeichert',
        created: '{objectName} erstellt',
        saveFailed: 'Änderungen konnten nicht gespeichert werden',
        createFailed: 'Konnte {objectName} nicht erstellen. {additionalInfo}',
        deleted: '{objectName} gelöscht',
        more: 'Mehr',
        requestSent: 'Anfrage gesendet',
        resendVerification: 'Bestätigungsmail erneut senden',
        next: 'Weiter',
        verifyEmail: 'Bitte verifiziere deine E-Mail bevor du fortfahrst.',
        resendReceipt: 'Quittung erneut senden',
        checkDelete: 'Willst du dieses Element tatsächlich löschen?',
        help: 'Hilfe',
        downloadAgain: 'Nochmal herunterladen',
        back: 'Zurück',
        associate: 'Produkt anwenden'
    },
    modal: {
        close: 'Schliessen',
        receipt: 'Quitung anzeigen',
        unsubscribe: '<span style="font-size: larger;">Wir haben bemerkt, dass du das Newsletterabo beendet hast<br>Kannst du uns sagen wieso?</span>',
        submit: 'Absenden',
        id: '{objectName} ID',
        name: '{objectName} Name',
        variant: 'Produktvarianten',
        variantPrice: 'Kosten Variante',
        variantDuration: 'Dauer Variante',
        variantSourceID: 'Varianten Quellen IDs',
        shopifySent: 'Sie sollten innerhalb der nächsten Minuten eine Email mit einem Link erhalten, über welchen Sie Ihr Abo verwalten können',
        variantUses: 'Variante Nutzungen'
    },
    license: {
        licenses: 'Lizenzen',
        licensesNotFound: 'Keine Lizenzen gefunden',
        currentSubscription: 'Laufende Abos',
        expiredSubscription: 'Abgelaufene Abos',
        name: 'Name',
        buy: 'Kaufdatum',
        expire: 'Ablaufdatum',
        method: 'Methode',
        feature: 'Feature',
        show: 'Lizenz zeigen',
        productID: 'Produkt Name',
        variantID: 'Varianten Name',
        paymentSource: 'Zahlungsquelle',
        startSub: 'Abobeginn',
        endSub: 'Aboende',
        isActive: 'Momentan Aktiv',
        autoRenew: 'Automatische Auffrischung',
        reSubscribe: 'Erneut Abonieren',
        lifeTime: 'Läuft nicht ab',
        redirectShop: 'Produkte ansehen',
        key: 'Schlüssel',
        appDownload: 'App Download',
        eBookDownload: 'E-Book Download',
        manageSubscription: 'Abo verwalten'
    },
    session: {
        activeSessions: 'Aktive Sitzungen',
        source: 'Quelle',
        firstDate: 'Erste Anmeldung',
        lastUse: 'Letzter Gebrauch',
        destroy: 'Sitzung beenden'
    },
    email: {
        validationTrying: 'Wir versuchen ihre Email zu validieren',
        validationError: 'Wir konnten ihre Email leider nicht verifizieren.',
        validationSuccess: 'Email validiert',
        notValidated: 'E-Mail-Adresse ist nicht verifiziert. Bitte verifiziere deine E-Mail-Adresse via der zugesendeten E-Mail.'
    },
    language: {
        language: 'Sprache',
        german: 'Deutsch',
        english: 'Englisch',
        portuguese: 'Portugiesisch',
        danish: 'Dänisch',
        spanish: 'Spanisch',
        french: 'Französisch',
        italian: 'Italienisch',
        croatian: 'Kroatisch',
        hungarian: 'Ungarisch',
        dutch: 'Niederländisch',
        portugueseBR: 'Portugiesisch (Brazilien)',
        russian: 'Russisch',
        slovenian: 'Slowenisch',
        chinese: 'Chinesisch'

    },
    select: {
        noLike: `Mag keine Newsletter`,
        noCare: `Interiessiert mich nicht`,
        noReason: 'Keinen speziellen Ground/Möchte nicht sagen'
    },
    voucher: {
        voucher: 'Gutschein',
        key: 'Schlüssel',
        redeem: 'Gutschein einlösen',
        uploaded: 'Voucher hochgeladen',
        uploadFailed: 'Voucher konnten nicht hochgeladen werden',
        redeemText: 'Hast du einen Gutschein für eines unserer Bundles? Dann kannst du diesen auch gleich hier einlösen.',
        redeemNow: 'Jetzt einlösen',
        enterCode: 'Bitte füge deinen Gutschein Code aus deinem Paket ein, so erhältst du Zugang zur App. Achte dabei bitte auf Groß- und Kleinschreibung.',
        referralCode: 'Gutschein Code',
        associate: 'Gutschein auf {user} anwenden?',
        noAppKey: 'Keinen Schlüssel gefunden. Bitte suche deinen Schlüssel unter Lizenzen nochmal.'
    },
    bundle: {
        title: 'Hole dir ein Instrument und eine Musik-App',
        text: 'Kein Gutschein zum einlösen und immer noch nach einem Instrument am suchen? Dann schau dir unsere exklusiven Instrumentenbundles an.',
        button: 'Zeige mir die Bundles'
    },
    types: {
        none: 'Keine Angabe',
        teacher: 'Lehrer',
        student: 'Schüler',
        regular: 'Normal',
        school: 'Schule',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Produkt Schlüssel',
        product: 'Produkt',
        user: 'Benutzer',
        shop: 'Shop',
        false: 'Nein',
        true: 'Ja'
    },
    toast: {
        failedRequest: '{requestGoal} fehlgeschlagen. {addInfo}',
        successRequest: '{requestGoal} erfolgreich',
        invalidInput: 'Eingaben nicht korrekt. Überprüfe ob alle benötigten Angaben korrekt ausgefüllt sind',
        notSent: 'Konnte Anfrage nicht senden',
        noIdFound: 'Keine ID gefunden',
        error: 'Fehler'
    },
    request: {
        redeemVoucher: 'Gutschein einlösen',
        pwReset: 'Versendung von Reset-Mail',
        resetPWValue: 'Passwort zurücksetzen',
        deleteUser: 'Benutzer löschen',
        createUser: 'Benutzer erstellen',
        saveUser: 'Benutzeränderungen ändern',
        deleteProduct: 'Produkt löschen',
        createProduct: 'Produkt erstellen',
        saveProduct: 'Produktänderungen ändern',
        resendVerification: 'Erneutes senden von Validierungs E-Mail',
        sendManagementLink: 'Verwaltungslink senden'
    },
    addInfo: {
        contactSupport: 'Bitte kontaktiere support@classplash.com für Hilfe.'
    },
    welcome: {
        step: '{nr}. Schritt',
        signup: `Ich habe noch kein Konto`,
        login: 'Ich habe schon ein Konto',
        hello: '<span style="font-size: 35px;">Hallo.<br/>Willkommen zur Welt der Musik</span>',
        weGuide: '<span style="font-size: 22px;">Wir werden dich Schritt für Schritt durch die Aktivierung deines Produkts leiten.<br/>Klicke einfach Weiter.</span>',
        selectBundle: 'Wähle dein Packet',
        bundleMissing: `Nicht hier?<br/>Kontaktiere uns.`,
        createAccount: 'Erstelle dein Classplash Konto',
        loginAccount: 'Melde dich auf unserem Portal an.',
        great: 'Großartig!',
        install: 'Lade dir die kostenlose App herunter und installiere diese auf deinem Gerät',
        language: 'Wähle in der App deine Sprache.',
        selectLogin: 'Wähle dieses Icon und klicke anschließend auf Einloggen.',
        selectLicense: 'Wähle: "Ich habe eine Lizenz..."',
        doLogin: 'Melde dich mit deinem Classplash Konto an',
        insertLicense: 'Gebe diese Lizenz in der App ein. Nun ist die App vollständig freigeschalten.',
        note: 'Hinweis: Du findest deine Lizenzen immer in unserem Portal',
        howTo: 'Wie nutzt du unsere Apps?',
        eBook: 'Willst du ein wunderschönes e-book?',
        tip: 'Wichtiger Tipp:',
        tipExp: `Vergiss bitte nicht deine Ukulele mehrere Male zu stimmen, da die Saiten anfangs ihre Spannung verlieren. Das ist völlig normal, da diese erst einige male gedehnt werden müssen. Viel Spaß!`,
        done: 'Fertig! Du bist bereit! Viel Spass!',
        video: 'Erklär Video',
        videoDesc: 'Wir haben ein kurzes Video erstellt, welches die die einzelnen Schritet nochmals erklärt.',
        videoSrc: 'https://www.youtube.com/embed/rnZIe6M1GKo?rel=0',
        createAccountDesc: `Damit du deine Lizenz für deine App erhältst, oder um dich in die Classplash Apps einloggen zu können, 
        musst du vorher einen Account erstellen und anschließend dein Voucher einlösen. Solltest du bereits ein Account haben, klicke auf “Ich habe ein Konto“.`,
        faq: `<div>Solltest du Probleme bei der Nutzung deiner App haben, besuche bitte unsere FAQ <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a> 
        oder wende dich direkt an <a href="mailto:support@classplash.de">support@classplash.com</a></div>`,
        license: 'Deine Lizenz',
        linkHowTo: 'PL7nCql3jN6U5We8tuQ0fxmsyRnhqJPruw'
    },
    error: {
        invalid: 'Gutschein ungültig',
        redeemed: 'Gutschein wurde bereits eingelöst',
        duplicate: 'Dieser Benutzer besitzt schon eine Lizenz von diesem Produkt.',
        contact: `Sollte der Fehler weiterhin bestehen, besuche bitte unsere FAQ <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a>
        oder wende dich direkt an <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a>.`
    },
    search: 'Suche',
    wom: wofDE,
    tmf: tmfDE,
    createVoucher: createVoucherDE
}

export default german;