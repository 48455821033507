import wofFR from "@/assets/languages/french/worldOfMusic";
import createVoucherFR from "@/assets/languages/french/createVoucher";
import tmfFR from "@/assets/languages/french/theMagicFlute";

const french = {
    title: 'Classplash – Portail clients',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Connectez-vous à notre portail pour continuer',
        login: 'Connectez-vous',
        noAccount: 'Pas encore de compte?',
        email: 'Adresse e-mail',
        password: 'Mot de passe',
        passwordConfirm: 'Confirmer le mot de passe',
        type: `Groupe d'utilisateurs`,
        newsletter: 'Bulletin d‘information',
        newsletterCheckbox: 'Je souhaite être informé des nouveaux produits et des mises à jour',
        agb: `J'ai lu et accepté les <a href="https://www.classplash.de/termsofuse" target="_blank">"Conditions générales"</a>` +
            `et <a href="https://www.classplash.de/privacypolicy" target="_blank">"l'Accord de confidentialité"</a> `,
        signup: 'Inscrivez-vous',
        createAccount: 'Crée ton compte Classplash gratuit',
        logout: 'Quitter',
        oldPW: 'Ancien mot de passe',
        saveData: 'Enregistrer',
        firstname: 'Prénom',
        lastname: 'Nom',
        name: 'Nom',
        invalid: {
            email: 'Veuillez saisir une adresse électronique valide.',
            pwConfirm: `Les mots de passe ne correspondent pas`,
            pwNotSecure: `Votre mot de passe n'est pas suffisamment sécurisé. Il doit contenir au moins un chiffre, une lettre minuscule et une lettre majuscule. Le mot de passe doit comporter au moins 6 caractères.`
        },
        admin: 'Administrateur',
        forgot: 'Vous avez oublié votre mot de passe?',
        reset: 'Réinitialiser le mot de passe',
        redeemVoucher: 'Utiliser un bon/voucher?',
        videoTutorial: 'Tutoriel vidéo',
        request: 'Envoyer une demande',
        resetSuccess: 'Mot de passe réinitialisé avec succès',
        resetFail: 'Impossible de réinitialiser le mot de passe',
        newPW: 'Nouveau mot de passe',
        failed: `L’accès a échoué. Vérifiez si l'adresse e-mail et le mot de passe sont corrects.`,
        signupFailed: {
            general: `L'enregistrement a échoué. Vérifiez si toutes les informations requises ont été remplies`,
            passwordNotSecure: `L'enregistrement a échoué. Votre mot de passe n'est pas assez sécurisé. Il doit contenir au moins un chiffre, une lettre minuscule et une lettre majuscule. Le mot de passe doit comporter au moins 6 caractères.`,
            emailAlreadyExists: 'Enregistrement échoué: l\'adresse électronique a déjà été saisie. Veuillez-vous connecter avec votre compte existant ou utiliser une autre adresse électronique. Si l\'erreur persiste, veuillez visiter notre FAQ www.classplash.de/faq ou contactez directement support@classplash.com.'
        },
        acceptAGB: `Veuillez accepter les "Conditions d'utilisation" et la "Politique de confidentialité" avant de poursuivre`,
        verificationSent: `Veuillez confirmer l'adresse e-mail pour terminer l'enregistrement.`,
        haveAccount: `J'ai un compte`,
        checkSpam: 'Vérifie ton dossier spam si tu n\'as pas reçu l\'email de confirmation!'
    },
    user: {
        profileTitle: 'Votre compte Classplash',
        name: 'Nom',
        subscribe: 'Inscrivez-vous',
        unsubscribe: 'Cliquez ici pour vous désinscrire du bulletin d’information',
        newsletterState: 'Statut du bulletin d’information',
        notVerified: `Votre adresse e-mail n'a pas encore été vérifié. Veuillez vérifier votre adresse e-mail dès que possible.`,
        verified: 'Vérifié',
        pendingEmail: `En attente d'un courriel`
    },
    button: {
        edit: 'Modifier',
        delete: 'Supprimer',
        create: 'Créer {objectName}',
        upload: 'Charger le bon',
        userManagement: 'Administration des utilisateurs',
        productManagement: 'Gestion des produits',
        cancel: 'Anuler',
        close: 'Fermer',
        save: 'Enregistrer',
        add: '{objectName} ajouter',
        saved: 'Les modifications sont enregistrées',
        created: '{objectName} créé',
        saveFailed: `Les modifications n'ont pas pu être enregistrées`,
        createFailed: 'Impossible de créer {objectName}. {additionalInfo}',
        deleted: '{objectName} supprimé',
        more: 'Plus',
        requestSent: 'Demande envoyée',
        resendVerification: `L'e-mail de confirmation est envoyé à nouveau`,
        next: 'Suivant',
        verifyEmail: `Veuillez vérifier l'email avant de continuer.`,
        resendReceipt: 'Renvoyer le reçu',
        checkDelete: 'Voulez-vous vraiment supprimer cet élément?',
        help: 'Aide',
        downloadAgain: 'Télécharger à nouveau',
        back: 'Précédent',
        associate: 'Apliquer un produit'
    },
    modal: {
        close: 'Fermer',
        receipt: 'Montrer le reçu',
        unsubscribe: '<span style="font-size: larger;">Nous avons remarqué que vous vous êtes désinscrit du bulletin d‘information <br>Pouvez-vous nous dire pourquoi? </span>',
        submit: 'Envoyer',
        id: '{objectName} ID',
        name: '{objectName} Nom',
        variant: 'Variantes de produits',
        variantPrice: 'Variante de coût',
        variantDuration: 'Variante de la durée',
        variantSourceID: 'Source des variantes ID',
        shopifySent: 'Vous devriez recevoir un mail dans les prochaines minutes. Il comprendra un lien avec lequel vous pourrez gérer votre abonnement',
        variantUses: 'Variante Utilisations'
    },
    license: {
        licenses: 'Licences',
        licensesNotFound: 'Aucune licence trouvée',
        currentSubscription: 'Abonnements en cours',
        expiredSubscription: 'Abonnements expirés',
        name: 'Nom',
        buy: `Date d'achat`,
        expire: `Date d'expiration`,
        method: 'Méthode',
        feature: 'Caractéristique',
        show: 'Montrer la licence',
        productID: 'Nom du produit',
        variantID: 'Nom de la variante',
        paymentSource: 'Source de paiement',
        startSub: `Début de l'abonnement`,
        endSub: `Fin de l'abonnement`,
        isActive: 'Actuellement actif',
        autoRenew: 'Mise à jour automatique',
        reSubscribe: 'Inscrivez-vous à nouveau',
        lifeTime: `N'expire pas`,
        redirectShop: 'Voir les produits',
        key: 'Clé',
        appDownload: `Télécharger l'application`,
        eBookDownload: 'Télécharger le livre électronique',
        manageSubscription: `Gérer l'abonnement`
    },
    session: {
        activeSessions: 'Sessions actives',
        source: 'Source',
        firstDate: 'Premier accès',
        lastUse: 'Dernière utilisation',
        destroy: 'La session est terminée'
    },
    email: {
        validationTrying: 'Nous essayons de valider votre adresse e-mail',
        validationError: `Malheureusement, nous n'avons pas pu vérifier votre adresse électronique.`,
        validationSuccess: 'Adresse e-mail validé',
        notValidated: `L'adresse électronique n'est pas vérifiée. Veuillez vérifier votre adresse électronique dans l'e-mail que vous avez reçu.`
    },
    language: {
        language: 'Langue',
        german: 'Allemand',
        english: 'Anglais',
        portuguese: 'Portugais',
        danish: 'Danois',
        spanish: 'Espagnol',
        french: 'Français',
        italian: 'Italien',
        croatian: 'Croate',
        hungarian: 'Hongrois',
        dutch: 'Néerlandais',
        portugueseBR: 'Portugais (Brésil)',
        russian: 'Russe',
        slovenian: 'Slovène',
        chinese: 'Chinois'

    },
    select: {
        noLike: `Je ne veux pas de bulletins d'information`,
        noCare: `Je ne suis pas intéressé`,
        noReason: 'Pas de raison particulière/Je ne veux pas le dire'
    },
    voucher: {
        voucher: 'Bon d\'achat',
        key: 'Clé',
        redeem: 'Échanger le bon',
        uploaded: 'Charger le bon',
        uploadFailed: `Les bons n'ont pas pu être chargés`,
        redeemText: `Avez-vous un bon pour l'un de nos forfaits ? Alors vous pouvez le échanger ici.`,
        redeemNow: 'Échanger maintenant',
        enterCode: 'Veuillez entrer le code de votre bon pour votre forfait. Cela vous donne accès à l\'application. Veuillez faire attention aux lettres majuscules et minuscules.',
        referralCode: 'Code promotionnel',
        associate: 'Appliquez le coupon à l\'e-mail {user}?',
        noAppKey: 'Aucune clé trouvée. Cherchez à nouveau votre clé parmi les licences.'
    },
    bundle: {
        title: 'Procurez-vous un outil et une application pour la musique',
        text: `Vous n'avez pas de bons à échanger et vous cherchez toujours un outil? Découvrez ensuite nos offres groupées exclusives.`,
        button: 'Montrez-moi les offres groupées'
    },
    types: {
        none: 'Aucune information',
        teacher: 'Enseignant',
        student: 'Étudiant',
        regular: 'Normal',
        school: 'École',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Clé du produit',
        product: 'Produit',
        user: 'Utilisateur',
        shop: 'Acheter',
        false: 'Non',
        true: 'Oui'
    },
    toast: {
        failedRequest: '{requestGoal} a échoué. {addInfo}',
        successRequest: '{requestGoal} a réussi',
        invalidInput: 'Les entrées ne sont pas correctes. Vérifiez si toutes les informations requises ont été remplies correctement.',
        notSent: `Impossible d'envoyer la demande`,
        noIdFound: 'Aucun identifiant trouvé',
        error: 'Erreur'
    },
    request: {
        redeemVoucher: 'Échanger le bon',
        pwReset: 'Envoyer un e-mail de récupération',
        resetPWValue: 'Réinitialiser le mot de passe',
        deleteUser: 'Supprimer un utilisateur',
        createUser: 'Créer un utilisateur',
        saveUser: `Changer les modifications apportées à l'utilisateur`,
        deleteProduct: 'Supprimer un produit',
        createProduct: 'Créer un produit',
        saveProduct: 'Changer les modifications apportées au produit',
        resendVerification: `Renvoyer l'e-mail de confirmation`,
        sendManagementLink: 'Envoyer le lien de gestion'
    },
    addInfo: {
        contactSupport: `Veuillez contacter support@classplash.com pour obtenir de l'aide.`
    },
    welcome: {
        step: '{nr}. Étape',
        signup: `Je n'ai pas encore de compte`,
        login: `J'ai déjà un compte`,
        hello: '<span style="font-size: 35px;">Salut.<br/>Bienvenue dans le monde de la musique</span>',
        weGuide: `<span style="font-size: 22px;">Nous vous guiderons pas à pas dans l'activation de votre produit.<br/> Il suffit de cliquer sur Suivant.</span>`,
        selectBundle: 'Choisissez votre forfait',
        bundleMissing: `N’est-il pas présent?<br/>Contactez-nous.`,
        createAccount: 'Créez votre compte Classplash',
        loginAccount: 'Connectez-vous sur notre portail.',
        great: 'Génial!',
        install: `Téléchargez l'application gratuite et installez-la sur votre appareil.`,
        language: 'Choisissez votre langue dans l\'application.',
        selectLogin: 'Sélectionnez cette icône, puis cliquez sur "Connectez-vous".',
        selectLicense: `Select: "J'ai une licence..."`,
        doLogin: 'Connectez-vous avec votre compte Classplash',
        insertLicense: 'Entrez maintenant cette licence dans l\'application. Maintenant, l\'application est entièrement activée!',
        note: 'Note: vous pouvez toujours trouver vos licences dans notre portail',
        howTo: 'Comment utilisez-vous nos applications?',
        eBook: 'Voulez-vous un super livre électronique?',
        tip: 'Conseil important:',
        tipExp: `N'oubliez pas d'accorder votre ukulélé plusieurs fois, car les cordes perdent leur tension au début. C'est tout à fait normal, car elles doivent d'abord être étirées plusieurs fois. Amusez-vous bien!`,
        done: `'C'est fait! Vous êtes prêts! Amusez-vous bien!`,
        video: 'Tutoriel vidéo',
        videoDesc: 'Nous avons créé une courte vidéo qui explique les différentes étapes séparément.',
        videoSrc: ' https://www.youtube.com/embed/1BEqcom7c1Y?rel=0',
        createAccountDesc: 'Pour recevoir la licence de votre application ou pour accéder aux Apps Classplash, ' +
            'vous devez d\'abord créer un compte, puis utiliser votre bon. Si vous avez déjà un compte, cliquez sur "J\'ai un compte".',
        faq: `<div>Si vous rencontrez des problèmes lors de l'utilisation de l'application, veuillez visiter notre FAQ 
        <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a> ou contactez directement 
        <a href="mailto:support@classplash.de">support@classplash.com</a>.</div>`,
        license: 'Votre licence',
        linkHowTo: 'PL7nCql3jN6U5hwT3GzrJKmaL7qkerXsUw'
    },
    error: {
        invalid: 'Coupon invalide',
        redeemed: '. Le bon a déjà été utilisé.',
        duplicate: 'Cet utilisateur possède déjà une licence pour ce produit.',
        contact: `Si l'erreur persiste, veuillez visiter notre FAQ <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a>
        ou contactez directement <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a>.
`
    },
    search: 'Chercher',
    wom: wofFR,
    tmf: tmfFR,
    createVoucher: createVoucherFR
}

export default french;
