const wofPT = {
    download: 'Fixe! Obrigado! Agora descarregue o Lançador de Aplicações Musicais World of Music e comece a sua nova experiência!',
    downloadSubtitle: 'Lembre-se que pode verificar a sua licença em qualquer altura. Inicie a sessão e seleccione "licenças".',
    downloadButton: 'BAIXAR AGORA',
    platform: 'Seleciona a tua plataforma',
    manual: 'Diário de bordo do World of Music - Manual {extension}',
    voucher: 'Desconto Exclusivo para os teus alunos - voucher',
    appTitle: 'World of Music Launcher',
    discountVoucher: 'Voucher com descontos para alunos',
    apps: 'Aplicações móveis disponíveis',
    rank: 'Olá e bem-vindos ao World of Music Apps! Qual é a tua posição como astronauta?',
    student: 'Alunos 2.0',
    teacher: 'Professor 2.0',
    admin: 'Escola Admin',
}

export default wofPT