const tmfEN = {
    issues: 'If you have problems using the app, please visit our FAQ www.classplash.de/faq or contact support@classplash.com or call +49 721 619 020 20 (we speak German, English or Portuguese).\n' +
        '\n' +
        'We are a small but dedicated team and would be very happy to receive a review, e.g. in the app stores (Google Play, Apple) or in the web shop where you bought your bundle (e.g. Amazon, eBay etc.).\n' +
        '\n' +
        'Thanks a lot for this!\n' +
        '\n' +
        'Your Classplash team\n',
    step: 'Step',
    firstStep: 'Download the free app "The Magic Flute" and install it on your device',
    secondStep: 'Select your language in the app and click on the icon at the bottom right and log in with your Classplash account'
}

export default tmfEN