const tmfFR = {
    issues: 'Si vous rencontrez des problèmes lors de l\'utilisation de l\'application, veuillez consulter notre FAQ www.classplash.de/faq ou contacter support@classplash.com ou appeler le +49 721 619 020 20 (nous parlons allemand, anglais ou portugais).\n' +
        '\n' +
        'Nous sommes une équipe petite mais dévouée et nous serions très heureux de recevoir un avis, par exemple dans les app stores (Google Play, Apple) ou dans la boutique en ligne où vous avez acheté votre bundle (par exemple Amazon, eBay, etc.).\n' +
        '\n' +
        'Merci beaucoup pour cela!\n' +
        '\n' +
        'Votre équipe Classplash\n',
    step: 'Étape',
    firstStep: 'Télécharge l\'application gratuite "La Flûte enchantée" et installe-la sur ton appareil',
    secondStep: 'Choisis ta langue dans l\'application, clique sur l\'icône en bas à droite et connecte-toi avec ton compte Classplash',
}

export default tmfFR