









import Vue from 'vue';
import CustomHeader from "@/components/CustomHeader.vue";
import { onMounted } from "@vue/composition-api";
import { translate } from "@/plugins/multi-language";

export default Vue.extend({
    name: 'App',
    components: { CustomHeader },
    setup() {
        onMounted(() => {
            document.title = translate('title').toString()
        })
    }
})
