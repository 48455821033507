import english from "@/assets/languages/english";
import german from "@/assets/languages/german";
import portuguese from "@/assets/languages/portuguese";
import italian from "@/assets/languages/italian";
import french from "@/assets/languages/french";
import spanish from "@/assets/languages/spanish";

export const messages = {
    en: english,
    de: german,
    pt: portuguese,
    it: italian,
    fr: french,
    esp: spanish
}