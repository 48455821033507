import wofPT from "@/assets/languages/portuguese/worldOfMusic";
import createVoucherPT from "@/assets/languages/portuguese/createVoucher";
import tmfPT from "@/assets/languages/portuguese/theMagicFlute";

const portuguese = {
    title: 'Classplash - Area de Clientes',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Faça Login no nosso Portal',
        login: 'Log-in',
        noAccount: `Não possui uma Conta?`,
        email: 'Email',
        password: 'Palavra-passe',
        passwordConfirm: 'Confirmar Palavra-passe',
        type: 'Grupo de Utilizador',
        newsletter: 'Newsletter',
        newsletterCheckbox: 'Quero ser informado de novos produtos e novidades',
        agb: "Li e entendi os " +
            "<a href=\"https://www.classplash.de/termsofuse\" target=\"_blank\">Termos de Utilização</a> e " +
            "<a href=\"https://www.classplash.de/privacypolicy\" target=\"_blank\">Política de Privacidade</a>",
        signup: 'Registar',
        createAccount: 'Cria a tua conta Classplash grátis',
        logout: 'Log-out',
        oldPW: 'Palavra-passe Antiga',
        saveData: 'Salvar',
        firstname: 'Nome Próprio',
        lastname: 'Apelido',
        name: '',
        invalid: {
            email: 'Por favor insira um email válido',
            pwConfirm: `As Palavras-passe não coicidem`,
            pwNotSecure: 'A sua palavra-passe não é suficientemente segura. Deve conter pelo menos um número, uma letra minúscula e uma letra maiúscula. A palavra-passe deve ter pelo menos 6 caracteres.'
        },
        admin: 'Administrador',
        forgot: 'Esqueceu a Palavra-passe?',
        reset: 'Redefinir Palavra-passe',
        redeemVoucher: 'Vale de resgate/voucher?',
        videoTutorial: 'Tutorial em vídeo',
        request: 'Enviar Pedido',
        resetSuccess: 'Palavra-passe redefinida com sucesso',
        resetFail: 'Não é possível redefinir a Palavra-passe',
        newPW: 'Nova Palavra-passe',
        failed: 'Login falhou. Verifique se o seu email e password estão correctos.',
        signupFailed: {
            general: 'Registo falhou.',
            passwordNotSecure: 'Registo falhou. A sua palavra-passe não é suficientemente segura. Deve conter pelo menos um número, uma letra minúscula e uma letra maiúscula. A palavra-passe deve ter pelo menos 6 caracteres.',
            emailAlreadyExists: 'Registo falhou. Endereço de email já existe. Por favor, faz o teu login com a tua conta existente ou usa outro endereço de e-mail. Se o erro persistir, visita o nosso FAQ em www.classplash.de/faq ou entra em contacto através do support@classplash.com.'
        },
        acceptAGB: 'Por favor aceite os Termos de Utilização e Política de Privacidade antes de continuar',
        verificationSent: 'Por favor confirme o seu Email para continuar o registo',
        haveAccount: 'Eu tenho uma conta!',
        checkSpam: 'Por favor verifica a tua pasta de spam se não recebeste o e-mail de confirmação!'
    },
    user: {
        profileTitle: 'O seu perfil Classplash',
        name: 'Nome',
        subscribe: 'Subscrever',
        unsubscribe: 'Clique aqui para terminar a subscrição',
        newsletterState: 'Estado da Newsletter',
        notVerified: 'O seu email ainda não está confirmado. Por favor confirme assim que possível',
        verified: '',
        pendingEmail: ''
    },
    button: {
        edit: 'Editar',
        delete: 'Eliminar',
        create: 'Criar {objectName}',
        upload: 'Carregar Voucher',
        userManagement: 'Gestão de Utilizadores',
        productManagement: 'Gestão de Produtos',
        cancel: 'Cancelar',
        close: 'Fechar',
        save: 'Salvar',
        add: 'Adicionar {objectName}',
        saved: 'Salvar Alterações',
        created: '{objectName} criado',
        saveFailed: `Não foi possível salvar alterações`,
        createFailed: `Não foi possível criar o utilizador. O Email já estará a ser utilizado.`,
        deleted: '{objectName} Eliminado',
        more: 'Mais',
        requestSent: 'Pedido enviado',
        resendVerification: 'Re-enviar email de confirmação',
        next: 'Seguinte',
        verifyEmail: 'Por favor verifique o seu email antes de prosseguir.',
        resendReceipt: 'Re-enviar recibo.',
        checkDelete: 'Tem a certeza que pretende eliminar?',
        help: 'Ajuda',
        downloadAgain: 'Descarregar novamente',
        back: 'Voltar',
        associate: 'Aplicar um produto'
    },
    modal: {
        close: 'Fechar',
        receipt: 'Mostrar Recibo',
        unsubscribe: '<span style="font-size: larger;">Reparámos que terminou a subscrição da Newsletter.<br>Pode dizer-nos a razão?</span>',
        submit: 'Enviar',
        id: '{objectName} ID',
        name: '{objectName} Nome',
        variant: 'Variações de Produto',
        variantPrice: 'Preço da Variação',
        variantDuration: 'Duração da Variação',
        variantSourceID: 'IDs da Fonte da Variação',
        variantUses: 'Usos das variantes'
    },
    license: {
        licenses: 'Licensas',
        licensesNotFound: 'Não foram encontradas licensas',
        currentSubscription: 'Subscrições Actuais',
        expiredSubscription: 'Subscrições Expiradas',
        name: 'Nome',
        buy: 'Data de Compra',
        expire: 'Data de Expiração',
        method: 'Métodp',
        feature: 'Funcionalidades Compradas',
        show: 'Mostrar Licensa',
        productID: 'Nome do Produto',
        variantID: 'Variação do Produto',
        paymentSource: 'Método de Pagamento',
        startSub: 'Iniciar Subscrição',
        endSub: 'Terminar Subscrição',
        isActive: 'Activas actualmente',
        autoRenew: 'Renovações Automáticas',
        reSubscribe: 'Re-Subscrever',
        lifeTime: 'Vitalícia',
        redirectShop: 'Procurar por Produtos',
        key: 'Chave',
        appDownload: 'Descarregar Aplicação',
        eBookDownload: 'Descarregar E-Book',
        manageSubscription: 'Gerenciar assinatura'
    },
    session: {
        activeSessions: 'Sessões Activas',
        source: 'Fonte',
        firstDate: 'Primeiro Login',
        lastUse: 'Ultima Utilização',
        destroy: 'Terminar Sessão'
    },
    email: {
        validationTrying: 'Estamos a tentar validar o seu Email',
        validationError: `Não conseguimos validar o seu Email`,
        validationSuccess: 'Email validado',
        notValidated: 'Endereço de email não validado. Por favor confirme através do email que lhe enviámos.'
    },
    language: {
        language: 'Lingua',
        german: 'Alemão',
        english: 'Inglês',
        portuguese: 'Português',
        danish: 'Dinamarquês',
        spanish: 'Espanhol',
        french: 'Françês',
        italian: 'Italiano',
        croatian: 'Croata',
        hungarian: 'Húngaro',
        dutch: 'Dutch',
        portugueseBR: 'Português (Brasil)',
        russian: 'Russo',
        slovenian: 'Esloveno',
        chinese: 'Chinês'
    },
    select: {
        noLike: `Não gosto de subscrições`,
        noCare: `Não me interessa`,
        noReason: 'Nenhuma razão especial/Prefiro não dizer'
    },
    voucher: {
        voucher: 'Voucher',
        key: 'Chave',
        redeem: 'Regatar Chave',
        uploaded: 'Vouchers carregados',
        uploadFailed: 'Os Vouchers não podem ser carregados',
        redeemText: 'Possui um Voucher dos nossos bundles? Então pode também resgata-lo aqui.',
        redeemNow: 'Resgatar Agora',
        enterCode: 'Por favor introduz o Código do Voucher que vem junto com o teu bundle. Este vai te dar acesso à aplicação. Por favor, presta atenção às letras maiúsculas e minúsculas.',
        referralCode: 'Código do Voucher',
        // ! Übersetzung von Rui prüfen lassen
        associate: 'Aplique o cupom no e-mail {user}?',
        noAppKey: 'Nenhuma chave encontrada. Procure sua chave novamente em Licenças.'
    },
    bundle: {
        title: 'Obtém um Instrumento e uma Aplicação de Música',
        text: 'Não possui um coucher mas procura um instrumento musical? Veja os nossos bundles exclusivos. ',
        button: 'Leve-me para os bundles'
    },
    types: {
        none: 'Não especificado',
        teacher: 'Professor',
        student: 'Estudante',
        regular: 'Comum',
        school: 'Escola',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Chave de Produto',
        product: 'Produto',
        user: 'Utilizador',
        shop: 'Loja',
        false: 'Não',
        true: 'Sim'
    },
    toast: {
        failedRequest: '{requestGoal} falhou. {addInfo}',
        successRequest: '{requestGoal} com sucesso',
        invalidInput: 'Entrada inválida. Verifique se preencheu todos os campos obrigatórios.',
        notSent: 'Não foi possível enviar pedido',
        noIdFound: 'Nenhum ID encontrado',
        error: 'Fracasso'
    },
    request: {
        redeemVoucher: 'resgatar Voucher',
        pwReset: 'Enviar email de redefinição de Palavra-Passe',
        resetPWValue: 'Redefinir Palavra-passe',
        resendVerification: 'reenviar email de verificação',
        sendManagementLink: 'Enviar link de gerenciamento'
    },
    addInfo: {
        contactSupport: 'Entre em contato com support@classplash.com para obter ajuda.'
    },
    welcome: {
        step: 'Passo {nr}',
        signup: `Eu não tenho uma Conta.`,
        login: 'Eu já tenho uma Conta',
        hello: '<span style="font-size: 35px;">Olá.<br/>Bem-vindo ao World of Music.</span>',
        weGuide: '<span style="font-size: 22px;">Iremos ajudar passo-a-passo para activar o seu produto.<br/>Clique continuar.</span>',
        selectBundle: 'Seleccione o Bundle adquirido',
        bundleMissing: `Não existe?<br/>Contacte-nos.`,
        createAccount: 'Crie a sua Conta Classplash',
        loginAccount: 'Entre no nosso Portal para continuar.',
        great: 'Fantástico!',
        install: 'Faz o download da aplicação gratuita e instala-a no teu dispositivo.',
        language: 'Seleciona o teu idioma na aplicação',
        selectLogin: 'Seleccione: "Clique aqui para entrar"',
        selectLicense: 'Seleccione: "Eu tenho uma licensa..."',
        doLogin: 'Entrar com a sua Conta Classplash',
        insertLicense: 'Agora insere esta licença na aplicação. A aplicação passa a estar totalmente ativada.',
        note: 'Nota: Irá encontrar sempre a sua licensa aqui, no nosso Portal.',
        howTo: 'Como utilizar as nossas aplicações?',
        eBook: 'Quer o nosso fantástico E-book?',
        tip: 'Dica importante:',
        tipExp: `Por favor não se esqueça de afinar o seu Ukulele com frequência. As cordas perdem tensão com alguma facilidade. Isto é normal, pelo que só tem que aperta-las. Divirta-se!`,
        done: 'Feito! Está pronto para começar! Divirta-se!',
        video: 'Vídeo tutorial',
        videoDesc: 'Criamos um vídeo que te explicam as etapas individuais.',
        videoSrc: 'https://www.youtube.com/embed/BYjPwRMVF7Y?rel=0',
        createAccountDesc: 'Para receber a sua licença de aplicação ou para poder entrar nas aplicações classplash, ' +
            'tens que criar primeiro uma conta. Posteriormente podes resgatar o teu voucher. Se já tens uma conta, cliqua em “Tenho uma conta”.',
        faq: `<div>Se tiveres problemas ao usar a tua aplicação, visita o nosso FAQ <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a>
        ou entra em contato diretamente através do <a href="mailto:support@classplash.de">support@classplash.com</a>.</div>`,
        license: 'A tua licença',
        linkHowTo: 'PL7nCql3jN6U5hwT3GzrJKmaL7qkerXsUw'
    },
    error: {
        invalid: 'Cupão inválido',
        redeemed: 'O voucher já foi resgatado.',
        duplicate: 'Este usuário já possui uma licença deste produto',
        contact: `Se o erro persistir, visita o nosso FAQ em <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a>
        ou entra em contacto através do <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a>.`
    },
    search: 'Pesquisa',
    wom: wofPT,
    tmf: tmfPT,
    createVoucher: createVoucherPT
}

export default portuguese;