const tmfPT = {
    issues: 'Se tiveres problemas ao usar a aplicação, visita a nossa FAQ www.classplash.de/faq ou entra em contacto connosco por e-mail em support@classplash.com ou por telefone em +351 915064210 (falamos alemão, inglês ou português).\n' +
        '\n' +
        'Somos uma equipa pequena, mas dedicada, e ficaríamos muito felizes em receber uma avaliação, por exemplo, nas app stores (Google Play, Apple) ou na loja virtual onde compraste o teu bundle (por exmeplo, Amazon, Fnac, eBay, etc.).\n' +
        '\n' +
        'Muito obrigado!\n' +
        '\n' +
        'A equipa Classplash\n',
    step: 'Etapa',
    firstStep: 'Descarregue a aplicação gratuita "A Flauta Mágica" e instale-a no seu dispositivo',
    secondStep: 'Escolha a sua língua na aplicação e clique no ícone no canto inferior direito e inicie a sessão com a sua conta Classplash',
}

export default tmfPT