import wofES from "@/assets/languages/spanish/worldOfMusic";
import createVoucherES from "@/assets/languages/spanish/createVoucher";
import tmfES from "@/assets/languages/spanish/theMagicFlute";

const spanish = {
    title: 'Classplash – Portal de clientes',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Inicie sesión en nuestro portal para continuar',
        login: 'Iniciar sesión',
        noAccount: '¿Todavía sin cuenta?',
        email: 'Correo electrónico',
        password: 'Contraseña',
        passwordConfirm: 'Confirmar la contraseña',
        type: 'Grupo de usuarios',
        newsletter: 'Boletín informativo',
        newsletterCheckbox: 'Me gustaría estar informado sobre nuevos productos y actualizaciones.',
        agb: 'He leído y aceptado los<a href="https://www.classplash.de/termsofuse" target="_blank">"Términos y Condiciones"</a> ' +
            'y <a href="https://www.classplash.de/privacypolicy" target="_blank">"el Acuerdo de privacidad"</a> ',
        signup: 'Registrarse',
        createAccount: 'Crea tu cuenta gratuita de Classplash',
        logout: 'Salir',
        oldPW: 'Contraseña antigua',
        saveData: 'Guardar',
        firstname: 'Nombre',
        lastname: 'Apellido',
        name: 'Nombre',
        invalid: {
            email: 'Por favor, introduzca un correo electrónico válido.',
            pwConfirm: `Las contraseñas no coinciden`,
            pwNotSecure: 'Su contraseña no es lo suficientemente segura. Debe contener al menos un número, una letra minúscula y una mayúscula. La contraseña debe tener al menos 6 caracteres.'
        },
        admin: 'Administrador',
        forgot: '¿Ha olvidado su contraseña?',
        reset: 'Restablecer la contraseña',
        redeemVoucher: '¿Canjear vale/cupón?',
        videoTutorial: 'Tutorial en vídeo',
        request: 'Enviar solicitud',
        resetSuccess: 'Contraseña restablecida con éxito',
        resetFail: 'No se ha podido restablecer la contraseña',
        newPW: 'Nueva contraseña',
        failed: 'Inicio de sesión fallido. Por favor, compruebe si su correo electrónico y su contraseña son correctos.',
        signupFailed: {
            general: 'Registro fallido. Compruebe si se ha rellenado toda la información requerida.',
            passwordNotSecure: 'Registro fallido. Su contraseña no es lo suficientemente segura. Debe contener al menos un número, una letra minúscula y una mayúscula. La contraseña debe tener al menos 6 caracteres.',
            emailAlreadyExists: 'Inscripción fallida: la dirección de correo electrónico ya ha sido registrada. Por favor, inicie sesión con su cuenta existente o utilice otra dirección de correo electrónico. Si el error persiste, visite nuestra página de preguntas frecuentes www.classplash.de/faq o póngase en contacto directamente con support@classplash.com.'
        },
        acceptAGB: 'Por favor, acepte las "Condiciones de uso" y la "Política de privacidad" antes de continuar.',
        verificationSent: 'Por favor, confirme su correo electrónico para completar el registro.',
        haveAccount: '¡Tengo una cuenta!',
        checkSpam: 'Por favor, comprueba tu carpeta de spam si no has recibido el correo electrónico de confirmación.'
    },
    user: {
        profileTitle: 'Su cuenta de Classplash',
        name: 'Nombre',
        subscribe: 'Registrarse',
        unsubscribe: 'Haga clic aquí para darse de baja del boletín informativo.',
        newsletterState: 'Estado del boletín informativo',
        notVerified: 'Su correo electrónico aún no ha sido confirmado. Por favor, confirme su correo electrónico lo antes posible.',
        verified: 'Confirmado',
        pendingEmail: 'En espera del correo electrónico'
    },
    button: {
        edit: 'Modificar',
        delete: 'Eliminar',
        create: 'Crear {objectName}',
        upload: 'Cargar el vale',
        userManagement: 'Gestión de usuarios',
        productManagement: 'Gestión de productos',
        cancel: 'Cancelar',
        close: 'Cerrar',
        save: 'Guardar',
        add: 'Añadir {objectName}',
        saved: 'Cambios guardados',
        created: '{objectName} creado',
        saveFailed: 'No se han podido guardar los cambios',
        createFailed: 'No se ha podido crear {objectName}. {additionalInfo}',
        deleted: '{objectName} eliminado',
        more: 'Más',
        requestSent: 'Solicitud enviada',
        resendVerification: 'Correo electrónico de confirmación enviado de nuevo',
        next: 'Siguiente',
        verifyEmail: 'Por favor, confirme su correo electrónico antes de continuar.',
        resendReceipt: 'Reenviar recibo',
        checkDelete: '¿Realmente quiere eliminar este elemento?',
        help: 'Ayuda',
        downloadAgain: 'Volver a descargar',
        back: 'Atrás',
        associate: 'Aplicar un producto'
    },
    modal: {
        close: 'Cerrar',
        receipt: 'Mostrar recibo',
        unsubscribe: '<span style="font-size: larger;">Nos hemos dado cuenta de que se ha dado de baja del boletín informativo. <br> ¿Puede decirnos por qué? </span>',
        submit: 'Enviar',
        id: '{objectName} ID',
        name: '{objectName} Name',
        variant: 'Producto',
        variantPrice: 'Precio',
        variantDuration: 'Duración',
        variantSourceID: 'ID de fuente',
        shopifySent: 'Debería recibir un correo en los próximos minutos. Incluirá un enlace con el que podrás gestionar tu suscripción.',
        variantUses: 'Usos variados'
    },
    license: {
        licenses: 'Licencias',
        licensesNotFound: 'No se ha encontrado ninguna licencia',
        currentSubscription: 'Suscripciones activas',
        expiredSubscription: 'Suscripciones vencidas',
        name: 'Nombre',
        buy: 'Fecha de compra',
        expire: 'Fecha de vencimiento',
        method: 'Método de pago',
        feature: 'Característica',
        show: 'Mostrar licencia',
        productID: 'Nombre del producto',
        variantID: 'Nombre de la variante',
        paymentSource: 'Origen del pago',
        startSub: 'Inicio de la suscripción',
        endSub: 'Fin de la suscripción',
        isActive: 'Actualmente activa',
        autoRenew: 'Actualización automática',
        reSubscribe: 'Volver a registrarse',
        lifeTime: 'Sin fecha de vencimiento',
        redirectShop: 'Ver productos',
        key: 'Clave',
        appDownload: 'Descargar la aplicación',
        eBookDownload: 'Descargar el libro electrónico',
        manageSubscription: 'Administrar suscripción'
    },
    session: {
        activeSessions: 'Sesiones activas',
        source: 'Fuente',
        firstDate: 'Primer acceso',
        lastUse: 'Último uso',
        destroy: 'Sesión terminada'
    },
    email: {
        validationTrying: 'Estamos intentando verificar su correo electrónico',
        validationError: 'Lamentablemente, no hemos podido verificar su correo electrónico.',
        validationSuccess: 'Correo electrónico verificado',
        notValidated: 'La dirección de correo electrónico no está verificada. Por favor, confirme su dirección de correo electrónico a través del e-mail que ha recibido.'
    },
    language: {
        language: 'Idioma',
        german: 'Alemán',
        english: 'Inglés',
        portuguese: 'Portugués',
        danish: 'Danés',
        spanish: 'Español',
        french: 'Francés',
        italian: 'Italiano',
        croatian: 'Croata',
        hungarian: 'Húngaro',
        dutch: 'Holandés',
        portugueseBR: 'Portugués (Brasil)',
        russian: 'Ruso',
        slovenian: 'Esloveno',
        chinese: 'Chino'

    },
    select: {
        noLike: `No quiero ningún boletín informativo`,
        noCare: `No me importa`,
        noReason: 'Ninguna razón en particular/no quiero decirlo'
    },
    voucher: {
        voucher: 'Voucher',
        key: 'Clave',
        redeem: 'Canjear el vale',
        uploaded: 'Cargar el vale',
        uploadFailed: 'No se ha podido cargar el vale',
        redeemText: '¿Tiene un bono para uno de nuestros paquetes? Entonces puedes canjearlo aquí mismo.',
        redeemNow: 'Canjear ahora',
        enterCode: 'Por favor, introduzca el código promocional para su paquete. Esto le da acceso a la aplicación. Preste atención a las letras mayúsculas y minúsculas.',
        referralCode: 'Código promocional',
        associate: '¿Aplicar cupón al correo electrónico {user}?',
        noAppKey: 'No se ha encontrado ninguna llave. Vuelva a buscar su clave entre las licencias.'
    },
    bundle: {
        title: 'Consiga un instrumento y una aplicación para la música',
        text: '¿No tiene vales para canjear y sigue buscando un instrumento? Entonces consulte nuestros exclusivos paquetes de instrumentos.',
        button: 'Muéstrame los paquetes'
    },
    types: {
        none: 'Ninguna información',
        teacher: 'Profesor',
        student: 'Estudiante',
        regular: 'Regular',
        school: 'Escuela',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Clave del producto',
        product: 'Producto',
        user: 'Usuario',
        shop: 'Comprar',
        false: 'No',
        true: 'Sí'
    },
    toast: {
        failedRequest: '{requestGoal} fallido. {addInfo}',
        successRequest: '{requestGoal} con éxito',
        invalidInput: 'Las entradas no son correctas. Compruebe si toda la información requerida se ha rellenado correctamente',
        notSent: 'No se pudo enviar la solicitud',
        noIdFound: 'No se encontró ID',
        error: 'Error'
    },
    request: {
        redeemVoucher: 'Canjear el vale',
        pwReset: 'Enviar correo electrónico de recuperación',
        resetPWValue: 'Restablecer la contraseña',
        deleteUser: 'Eliminar usuario',
        createUser: 'Crear usuario',
        saveUser: 'Modificar los cambios en el usuario',
        deleteProduct: 'Eliminar producto',
        createProduct: 'Crear producto',
        saveProduct: 'Modificar los cambios en el producto',
        resendVerification: 'Volver a enviar el correo electrónico de confirmación',
        sendManagementLink: 'Enviar enlace de gestión'
    },
    addInfo: {
        contactSupport: 'Póngase en contacto con support@classplash.com para obtener ayuda.'
    },
    welcome: {
        step: '{nr}. Paso',
        signup: `Todavía no tengo una cuenta`,
        login: 'Ya tengo una cuenta',
        hello: '<span style="font-size: 35px;">Hola. <br/> Bienvenido al mundo de la música </span>',
        weGuide: '<span style="font-size: 22px;"> Le guiaremos paso a paso en la activación de su producto. <br/> Sólo tiene que hacer clic en Siguiente. </span>',
        selectBundle: 'Elija su paquete',
        bundleMissing: `¿No está presente? <br/> Contacte con nosotros.`,
        createAccount: 'Cree su cuenta de Classplash',
        loginAccount: 'Regístrese en nuestro portal.',
        great: '¡Genial!',
        install: 'Descargue la aplicación gratuita e instálela en su dispositivo.',
        language: 'Seleccione el idioma en la aplicación.',
        selectLogin: 'Seleccione este icono y luego haga clic en Iniciar sesión.',
        selectLicense: 'Seleccione: "Tengo una licencia..."',
        doLogin: 'Inicie sesión con su cuenta de Classplash',
        insertLicense: 'Ahora introduzca esta licencia en la aplicación. La aplicación ya está totalmente activada.',
        note: 'Nota: siempre podrá encontrar sus licencias en nuestro portal',
        howTo: '¿Cómo utiliza nuestras aplicaciones?',
        eBook: '¿Quiere un fantástico libro electrónico?',
        tip: 'Consejo importante:',
        tipExp: `No se olvide de afinar su ukelele varias veces, ya que las cuerdas pierden su tensión al principio. Esto es perfectamente normal, ya que primero necesitan ser estiradas unas cuantas veces. ¡Que lo pase bien!`,
        done: '¡Hecho! ¡Está listo! ¡Que lo pase bien!',
        video: 'Videotutorial',
        videoDesc: 'Hemos creado un breve vídeo que explica cada paso por separado.',
        videoSrc: 'https://www.youtube.com/embed/LLa9p1vFJts?rel=0',
        createAccountDesc: 'Para recibir una licencia para su aplicación, o para acceder a las aplicaciones de classplash, ' +
            'primero debe crear una cuenta y luego canjear su cupón. Si ya tiene una cuenta, pinche en "Tengo una cuenta".',
        faq: `<div>Si tiene problemas para usar su aplicación, visite nuestra página de preguntas frecuentes  <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a>
        o póngase en contacto directamente con  <a href="mailto:support@classplash.de">support@classplash.com</a>.</div>`,
        license: 'Tu licencia',
        linkHowTo: 'PL7nCql3jN6U5hwT3GzrJKmaL7qkerXsUw'
    },
    error: {
        invalid: 'Cupón inválido',
        redeemed: '. El cupón ya ha sido canjeado.',
        duplicate: 'Este usuario ya tiene una licencia para este producto.',
        contact: `Si el error persiste, visite nuestra página de preguntas frecuentes <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a>
        o póngase en contacto directamente con <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a>.`
    },
    search: 'Buscar',
    wom: wofES,
    tmf: tmfES,
    createVoucher: createVoucherES
}

export default spanish;
