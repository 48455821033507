


















import { defineComponent } from "@vue/composition-api";
import LoginInterface from "@/components/LoginInterface.vue";

export default defineComponent({
    name: "Login",
    components: { LoginInterface }
});
