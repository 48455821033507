import wofEN from "@/assets/languages/english/worldOfMusic";
import createVoucherEN from "@/assets/languages/english/createVoucher";
import tmfEN from "@/assets/languages/english/theMagicFlute";

const english = {
    title: 'Classplash - Customer Portal',
    login: {
        adminPortal: 'Adminportal',
        welcome: 'Login on our portal to continue',
        login: 'Login',
        noAccount: `Register now!`,
        email: 'E-mail',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        type: 'User Group',
        newsletter: 'Newsletter',
        newsletterCheckbox: 'I want to be informed about new products and updates',
        agb: "I've read and understood the " +
            "<a href=\"https://www.classplash.de/termsofuse\" target=\"_blank\">terms of use</a> and " +
            "<a href=\"https://www.classplash.de/privacypolicy\" target=\"_blank\">privacy policy</a>",
        signup: 'Signup',
        createAccount: 'Create your free Classplash Account',
        logout: 'Logout',
        oldPW: 'Old Password',
        saveData: 'Save',
        firstname: 'Firstname',
        lastname: 'Lastname',
        name: 'Name',
        invalid: {
            email: 'Please enter a valid email',
            pwConfirm: `Passwords don't match`,
            pwNotSecure: 'Password is not secure enough. Please use at least one Number, a uppercase Letter and a lowercase Letter. The Password must be at least 8 characters long.'
        },
        admin: 'Admin',
        forgot: 'Forgot Password?',
        reset: 'Reset Password',
        redeemVoucher: 'Redeem voucher?',
        videoTutorial: 'Video Tutorial',
        request: 'Send Request',
        resetSuccess: 'Password successfully reset',
        resetFail: 'Could not reset password',
        newPW: 'New Password',
        failed: 'Login failed. Check if your email and password are correct.',
        signupFailed: {
            general: 'Signup failed.',
            passwordNotSecure: 'Signup failed. Password is not secure enough. Please use at least one Number, a uppercase Letter and a lowercase Letter. The Password must be at least 8 characters long.',
            emailAlreadyExists: 'Signup failed. E-mail address already registered. Please log in with your existing account or use another email address. If the error persists, please visit our FAQ www.classplash.de/faq or contact support@classplash.com directly.'
        },
        acceptAGB: 'Please accept the terms of use and privacy policy before continuing',
        verificationSent: 'Please confirm email to finish register',
        haveAccount: 'I have an account!',
        checkSpam: 'Please check your spam folder if you didn\'t receive the confirmation e-mail!'
    },
    user: {
        profileTitle: 'Your Classplash Profile',
        name: 'Name',
        subscribe: 'Subscribe',
        unsubscribe: 'Click here to unsubscribe',
        newsletterState: 'Newsletter status',
        notVerified: 'Your email is not verified yet. Please verify it as soon as possible',
        verified: 'Verified',
        pendingEmail: 'Pending Email'
    },
    button: {
        edit: 'Edit',
        delete: 'Delete',
        create: 'Create {objectName}',
        upload: 'Upload Voucher',
        userManagement: 'User Management',
        productManagement: 'Product Management',
        cancel: 'Cancel',
        close: 'Close',
        save: 'Save',
        add: 'Add {objectName}',
        saved: 'Saved changes',
        created: '{objectName} created',
        saveFailed: `Changes couldn't be save`,
        createFailed: `Couldn't create user. User email might already be in use.`,
        deleted: 'Deleted {objectName}',
        more: 'More',
        requestSent: 'Request sent',
        resendVerification: 'Resend verification email',
        next: 'Next',
        verifyEmail: 'Please verify your email before proceeding',
        resendReceipt: 'Resend receipt',
        checkDelete: 'Are you sure you want to delete this?',
        help: 'Help',
        downloadAgain: 'Download again',
        back: 'Back',
        associate: 'Apply Product'
    },
    modal: {
        close: 'Close',
        receipt: 'Show receipt',
        unsubscribe: '<span style="font-size: larger;">We noticed that you unsubscribed our Newsletter.<br>Can you tell us the Reason?</span>',
        submit: 'Submit',
        id: '{objectName} ID',
        name: '{objectName} Name',
        variant: 'Product variants',
        variantPrice: 'Variant Price',
        variantDuration: 'Variant Duration',
        variantSourceID: 'Variant Source IDs',
        shopifySent: 'You should recieve a mail in the next minutes. It will include a link with which you can manage your subscription',
        variantUses: 'Variant uses'
    },
    license: {
        licenses: 'Licenses',
        licensesNotFound: 'No licences found',
        currentSubscription: 'Current Subscriptions',
        expiredSubscription: 'Expired Subscriptions',
        name: 'Name',
        buy: 'Buy Date',
        expire: 'Expiration Date',
        method: 'Method',
        feature: 'Bought Features',
        show: 'Show License',
        productID: 'Product Name',
        variantID: 'Variant Name',
        paymentSource: 'Payment Method',
        startSub: 'Start Subscription',
        endSub: 'End Subscription',
        isActive: 'Currently Active',
        autoRenew: 'Automatically Renews',
        reSubscribe: 'Re-Subscribe',
        lifeTime: 'Lifetime',
        redirectShop: 'Look for Products',
        key: 'Key',
        appDownload: 'App Download',
        eBookDownload: 'E-Book Download',
        manageSubscription: 'Manage Subscription'
    },
    session: {
        activeSessions: 'Active Sessions',
        source: 'Source',
        firstDate: 'First Login',
        lastUse: 'Last Usage',
        destroy: 'Destroy Session'
    },
    email: {
        validationTrying: 'We are trying to validate your Email',
        validationError: `We couldn't verify your Email`,
        validationSuccess: 'Email validated',
        notValidated: 'E-Mail-Address is not verified. Please verify your Email-Address via the sent Email.'
    },
    language: {
        language: 'Language',
        german: 'German',
        english: 'English',
        portuguese: 'Portuguese',
        danish: 'Danish',
        spanish: 'Spanish',
        french: 'French',
        italian: 'Italian',
        croatian: 'Croatian',
        hungarian: 'Hungarian',
        dutch: 'Dutch',
        portugueseBR: 'Portuguese (Brazil)',
        russian: 'Russian',
        slovenian: 'Slovenian',
        chinese: 'Chinese'
    },
    select: {
        noLike: `Don't like subscriptions`,
        noCare: `Don't care`,
        noReason: 'No special reason/Rather not say'
    },
    voucher: {
        voucher: 'Voucher',
        key: 'Key',
        redeem: 'Redeem Voucher',
        uploaded: 'Vouchers uploaded',
        uploadFailed: 'Vouchers could not be uploaded',
        redeemText: 'You have a voucher from one of our bundles? Then you can also redeem it here right away.',
        redeemNow: 'Redeem it now',
        enterCode: 'Please insert the voucher code found in your bundle. This gives you access to the app. Please pay attention to upper and lower case letters.',
        referralCode: 'Voucher Code',
        associate: 'Apply coupon to {user}?',
        noAppKey: 'No Key found. Please look up your key under Licenses again.',
        redeemed: 'Redeemed Vouchers'
    },
    bundle: {
        title: 'Get an instrument and a music app',
        text: 'No voucher to redeem and still looking for an instrument? Then check out our exclusive instrument bundles.',
        button: 'Bring me to the bundles'
    },
    types: {
        none: 'Not specified',
        teacher: 'Teacher',
        student: 'Student',
        regular: 'Regular',
        school: 'School',
        android_iap: 'Google',
        apple_iap: 'Apple',
        shopify: 'Shopify',
        license_key: 'Product Key',
        product: 'Product',
        user: 'User',
        shop: 'Shop',
        false: 'No',
        true: 'Yes'
    },
    toast: {
        failedRequest: 'Failed to {requestGoal}. {addInfo}',
        successRequest: 'Successful to {requestGoal}',
        invalidInput: 'Entry invalid. Check if you filled all required correctly fields',
        notSent: 'Could not send Request',
        noIdFound: 'No ID found',
        error: 'Error'
    },
    request: {
        redeemVoucher: 'redeem voucher',
        pwReset: 'send password reset mail',
        resetPWValue: 'reset password',
        deleteUser: 'delete User',
        createUser: 'create User',
        saveUser: 'save User changes',
        deleteProduct: 'delete Product',
        createProduct: 'create Product',
        saveProduct: 'save Product changes',
        resendVerification: 'resend Verification Email',
        sendManagementLink: 'send Management Link'
    },
    addInfo: {
        contactSupport: 'Please contact support@classplash.com for help.'
    },
    welcome: {
        step: 'Step {nr}',
        signup: `I don't have an account`,
        login: 'I already have an account',
        hello: '<span style="font-size: 35px;">Hello.<br/>Welcome to the World of Music</span>',
        weGuide: '<span style="font-size: 22px;">We will guide step by step to activate your product.<br/>Just click next.</span>',
        selectBundle: 'Select your obtained bundle',
        bundleMissing: `Isn't here?<br/>Contact us.`,
        createAccount: 'Create your classplash account',
        loginAccount: 'Login on our portal to continue',
        great: 'Great!',
        install: 'Download the free app and install it on your device.',
        language: 'Select your language in the app.',
        selectLogin: 'Select: "Click here to login"',
        selectLicense: 'Select: "I have an license..."',
        doLogin: 'Log in with your classplash account',
        insertLicense: 'Now enter this license in the app. The app is now fully activated.',
        note: 'Note: You will always find your licensees here on our portal',
        howTo: 'How to use our apps?',
        eBook: 'Do you want our beautiful e-book?',
        tip: 'Important advice:',
        tipExp: `Please don't forget to tune your new ukulele several times, as the string lose their tension very easily. This is normal because they have to get stretched. Have fun!`,
        done: 'Done! You are good to go! Have fun!',
        video: 'Video Tutorial',
        videoDesc: 'We have created a short video that explains the individual steps again.',
        videoSrc: 'https://www.youtube.com/embed/BYjPwRMVF7Y?rel=0',
        createAccountDesc: 'In order to receive your license for your app or to be able to log into the classplash apps, ' +
            'you must first create an account and then redeem your voucher. If you already have an account, click on “I have an account”.',
        faq: `<div>If you have problems using your app, please visit our FAQ <a href="https://www.classplash.de/faq" target="_blank">www.classplash.de/faq</a>
        or contact <a href="mailto:support@classplash.de">support@classplash.com</a> directly.</div>`,
        license: 'Your license',
        linkHowTo: 'PL7nCql3jN6U5hwT3GzrJKmaL7qkerXsUw'
    },
    error: {
        invalid: 'Voucher not valid',
        redeemed: 'Voucher already redeemed',
        duplicate: 'This User already posses a License of this Product',
        contact: `If the error persists, please visit our FAQ <a href="https://www.classplash.de/faq" target="_blank" style="color: #856404">www.classplash.de/faq</a> 
        or contact <a href="mailto:support@classplash.de" style="color: #856404">support@classplash.com</a> directly.`
    },
    search: 'Search',
    wom: wofEN,
    tmf: tmfEN,
    createVoucher: createVoucherEN
}

export default english;