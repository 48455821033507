


















































































import {defineComponent} from "@vue/composition-api";
import {useAuth} from "@/api_connectors/auth";
import router from "@/router";
import {BIconPersonCircle} from 'bootstrap-vue'
import { translate } from "@/plugins/multi-language";
import VueI18n from "vue-i18n";

export default defineComponent({
    name: "CustomHeader",
    setup() {
        const {logout} = useAuth()

        const logoutUser = async () => {
            // console.log('logout')
            await logout()
            // console.log(response)
            if (router.currentRoute.meta?.admin) return await router.push('/admin')
            await router.push('/')
        }

        const changeLanguage = (language: string, module: VueI18n) => {
            module.locale = language;
            localStorage.setItem('localeLanguage', language)
            document.title = translate('title').toString()
        }

        return {
            logoutUser,
            changeLanguage,
            BIconPersonCircle
        }
    }
})
