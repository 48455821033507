const wofEN = {
    download: 'Cool! Thank you! Now download the World of Music App Launcher and start your new experience!',
    downloadSubtitle: 'Please remember you can check your License anytime. Log in and select "licenses".',
    downloadButton: 'DOWNLOAD NOW',
    platform: 'Select your platform',
    manual: 'World of Music Logbook - Manual {extension}',
    voucher: 'Exclusive Discount for your Students - Coupon',
    appTitle: 'World of Music Launcher',
    discountVoucher: 'Student Discount Voucher (PDF)',
    apps: 'Available Mobile Apps',
    rank: 'Hi and welcome to the World of Music Apps! What is your astronaut rank?',
    student: 'Student 2.0',
    teacher: 'Teacher 2.0',
    admin: 'School Admin',
}

export default wofEN