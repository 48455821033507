import Axios, { AxiosInstance } from "axios";
import router from "@/router";
import { useAuth } from "@/api_connectors/auth";

// const WORKSPACE_KEY = 'workspace'
export const TOKEN_KEY = 'authKey'
export const ADMIN_KEY = 'admin'

export const API_URL = ENV.API_URL;

const { getToken } = useAuth()

export function useApi() {
    const api: AxiosInstance = Axios.create({
        baseURL: API_URL,
    }); /* = reactive({} as AxiosInstance)*/

    function init() {
        // console.log('api setup')
        // console.log('api url', API_URL)

        api.interceptors.request.use(async function (config) {
            // Do something before request is sent
            config.headers["Authorization"] = "Bearer " + await getToken();
            return config;
        });

        api.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error: { response: { status: number; }; }) {
            if (error.response.status === 401) {
                if (router.currentRoute.meta?.auth) {
                    //Remove SessionID from Storage on Failed Auth User
                    localStorage.removeItem(TOKEN_KEY)
                    localStorage.removeItem(ADMIN_KEY)
                }
                router.replace({ name: 'Login' })
            }
            return Promise.reject(error);
        });
    }

    init();

    return {
        api
    }
}