const tmfIT = {
    issues: 'In caso di problemi nell\'utilizzo dell\'app, visitare le nostre FAQ www.classplash.de/faq o contattare support@classplash.com o chiamare il numero +49 721 619 020 20 (parliamo tedesco, inglese o portoghese).\n' +
        '\n' +
        'Siamo un team piccolo ma dedicato e saremmo molto felici di ricevere una recensione, ad es. negli app store (Google Play, Apple) o nel webshop in cui hai acquistato il bundle (es. Amazon, eBay ecc.).\n' +
        '\n' +
        'Grazie mille per questo!\n' +
        '\n' +
        'Il tuo team Classplash\n',
    step: 'Passo',
    firstStep: 'Scaricate l\'applicazione gratuita "Il flauto magico" e installatela sul vostro dispositivo',
    secondStep: 'Scegliete la vostra lingua nell\'app e cliccate sull\'icona in basso a destra per accedere con il vostro account Classplash',
}

export default tmfIT