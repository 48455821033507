const tmfDE = {
    issues: 'Solltest du Probleme bei der Nutzung deiner App haben, besuche bitte unsere FAQ www.classplash.de/faq ' +
        'oder wende dich an support@classplash.com oder telefonisch an +49 721 619 020 20. ' +
        'Wir sind ein kleines, aber engagiertes Team und würden uns sehr über eine Rezension freuen, ' +
        'bspw. in den App Stores (Google Play, Apple) oder bei dem Webshop, wo du dein Set erworben hast (bspw. Amazon, eBay usw.). ' +
        'Vielen Dank dafür! ' +
        'Dein Classplash-Team',
    step: 'Schritt',
    firstStep: 'Lade dir die kostenlose App "Die Zauberflöte" herunter und installiere diese auf deinem Gerät',
    secondStep: 'Wähle in der App deine Sprache und klicke rechts unten auf das Icon und melde dich mit deinem Classplash Konto an',
}

export default tmfDE
