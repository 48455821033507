









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "LoginToast",
    props: {
        mode: String,
        reason: String
    }
})
