import { computed } from "@vue/composition-api";
import { translate } from "@/plugins/multi-language";

const languageOptions = computed(() => {
    return [
        { value: 'da', text: translate('language.danish') },
        { value: 'de-DE', text: translate('language.german') },
        { value: 'en-GB', text: translate('language.english') },
        { value: 'es-ES', text: translate('language.spanish') },
        { value: 'fr-FR', text: translate('language.french') },
        { value: 'it-IT', text: translate('language.italian') },
        { value: 'hr', text: translate('language.croatian') },
        { value: 'hu', text: translate('language.hungarian') },
        { value: 'nl', text: translate('language.dutch') },
        { value: 'pt-PT', text: translate('language.portuguese') },
        { value: 'pt-BR', text: translate('language.portugueseBR') },
        { value: 'ru', text: translate('language.russian') },
        { value: 'sl', text: translate('language.slovenian') },
        { value: 'zh-CN', text: translate('language.chinese') },
    ]
})

export default languageOptions