import Vue from 'vue'

import BootstrapVue, {BootstrapVueIcons, BVConfigPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(BVConfigPlugin, {
    BToast: {autoHideDelay: 10000}
})
