import Vue from "vue";
import VueI18n from "vue-i18n";
import * as Messages from "@/assets/languages";

Vue.use(VueI18n)

const messages = Messages.messages

let localeLanguage = localStorage.getItem('localeLanguage')

if (!localeLanguage) {
    const language = navigator.language as string

    if (language.includes('de')) localeLanguage = 'de'
    if (language.includes('en')) localeLanguage = 'en'
    if (language.includes('pt')) localeLanguage = 'pt'
    if (language.includes('fr')) localeLanguage = 'fr'
    if (language.includes('it')) localeLanguage = 'it'
    if (language.includes('esp')) localeLanguage = 'esp'

    if (!localeLanguage) localeLanguage = 'en'
    localStorage.setItem('localeLanguage', localeLanguage)
}

const i18n = new VueI18n({
    locale: localeLanguage,
    fallbackLocale: 'en',
    messages
})

export function translate(identifier: string, values?: any[] | { [key: string]: any }) {
    return i18n.t(identifier, values)
}

export default i18n