import axios, { AxiosResponse } from "axios"
import { ADMIN_KEY, TOKEN_KEY, useApi } from "@/composables/useApi";

import { AuthCredentials, AuthResult, ResultType, Signup } from "@/types/typeDefinitions";
import { useToast } from "@/composables/useToast";
import { translate } from "@/plugins/multi-language";

const CLIENT_ID = 'app'

let authKey = ''

const { api } = useApi()
const { popToast } = useToast()

interface PasswordReset {
    token: string;
    password: string;
}

export function useAuth() {
    const signup = async (signupValues: Signup): Promise<AxiosResponse | undefined> => {
        const response = await axios.post(api.defaults.baseURL + 'auth/signup', signupValues)
            .catch((error) => {
                switch (error.response.data?.error) {
                    case ResultType.EmailAlreadyExists:
                        popToast({
                            message: translate('login.signupFailed.emailAlreadyExists').toString(),
                            variant: 'danger'
                        })
                        break;
                    case ResultType.InvalidPassword:
                        popToast({
                            message: translate('login.signupFailed.passwordNotSecure').toString(),
                            variant: 'danger'
                        })
                        break;
                    default:
                        popToast({
                            message: translate('login.signupFailed.general').toString(),
                            variant: 'danger'
                        })
                        break;
                }
                return undefined
            })

        if (!response) return undefined

        return response
    }

    const confirmEmail = async (verifyKey: string): Promise<string | undefined> => {
        const response = await axios.get(api.defaults.baseURL + 'api/userCredentials/verifyEmailToken/' + verifyKey)
            .catch((e) => {
                // console.log(e)
                return undefined
            })

        if (!response) return undefined
        return 'success'
    }

    const sendResetRequest = async (email: string): Promise<AxiosResponse | undefined> => {
        return await axios.post(api.defaults.baseURL + 'api/userCredentials/resetPassword', { email: email })
            .catch(() => {
                return undefined
            })
    }

    const resetPW = async (resetRequest: PasswordReset): Promise<boolean> => {
        return !!(await axios.post(api.defaults.baseURL + 'api/userCredentials/redeemPasswordToken', resetRequest)
            .catch(() => {
                return false;
            }))
    }

    const login = (credentials: AuthCredentials): Promise<AuthResult | undefined> => {
        return authRequest('auth/login', credentials)
    }

    const isEmailVerified = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('api/userCredentials/checkVerifyEmail')
            .catch(() => {
                return undefined;
            })
    }

    const logout = async () => {
        await localStorage.removeItem(TOKEN_KEY)
        await localStorage.removeItem(ADMIN_KEY)
        return api.post('/auth/logout')
    }

    const authRequest = async (path: string, credentials?: AuthCredentials | Signup): Promise<AuthResult | undefined> => {
        //* Here Axios so that Authorization Header is not used!!
        const response = await axios.post(api.defaults.baseURL + path, credentials)
            .catch((error) => {
                if (error.response.data?.errorMessage === 'Email not verified') return 'Email not verified'

                return undefined
            })

        if (!response) return undefined

        if (response === 'Email not verified') return { valid: false }

        if (response.data?.token) {
            await setToken(response.data.token)
            await setAdminPermission(response.data.isAdmin)
            return { valid: true, isAdmin: response.data.isAdmin }
        }
        return { valid: false }
    }

    async function setAdminPermission(permission: boolean) {
        await localStorage.setItem(ADMIN_KEY, permission.toString())
    }

    async function setToken(key: string) {
        await localStorage.setItem(TOKEN_KEY, key)

        authKey = key
    }

    async function getToken() {
        // console.log("getting Token", authKey)
        if (authKey !== '') {
            return authKey
        } else {
            const key = localStorage.getItem(TOKEN_KEY)
            // console.log(key)
            // const key = (await Storage.getItem({
            //     key: TOKEN_KEY
            // })).value

            if (key) {
                authKey = key
                return authKey
            }
            return 'error'
        }
    }

    async function resendVerification(email: string) {
        console.log(email)
        //TODO: Change API to work with email instead of session
        // await axios.post(api.defaults.baseURL + 'api/userCredentials/resendVerifyEmail/' + email)
    }

    return {
        signup,
        login,
        logout,
        getToken,
        confirmEmail,
        resetPW,
        sendResetRequest,
        resendVerification,
        isEmailVerified
    }
}