const wofDE = {
    download: 'Cool! Vielen Dank! Lade jetzt den World of Music App Launcher herunter und starte dein neues Erlebnis!',
    downloadSubtitle: 'Bitte denke daran, dass du deine Lizenz jederzeit überprüfen kannst. Logge dich ein und wähle "Lizenzen".',
    downloadButton: 'JETZT HERUNTERLADEN',
    platform: 'Wählen deine Plattform',
    manual: 'World of Music Logbuch - Handbuch {extension}',
    voucher: 'Exklusiver Rabatt für deine Schüler - Gutschein',
    appTitle: 'Welt der Musik Launcher',
    discountVoucher: 'Schüler-Rabatt-Gutschein',
    apps: 'Verfügbare mobile Apps',
    rank: 'Hallo und willkommen in der World of Music Apps! Was ist dein Astronautenrang?',
    student: 'Schüler 2.0',
    teacher: 'Lehrer 2.0',
    admin: 'Schule Admin',
}

export default wofDE