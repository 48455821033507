interface DBDocument {
    _id: string;
    updatedAt: string;
    createdAt: string;
}

interface License {
    // id: string;
    productId: string;
    variantId: string;
    type: string;
    purchaseDate: string;
    expirationDate?: string;
    isActive: boolean;
    autoRenew: boolean;
    appKey?: {
        id: string;
        key: string;
    }
}

interface LicenseListItem {
    productId: string;
    variantId: string;
    name?: string;
    purchaseDate: string;
    expirationDate: string | null;
    isActive: boolean;
    autoRenew: boolean;
    type: string;
    features: string | null;
    link: string;
    //might get replaced by actual picture data
    picturePath: string | null;
    appKey?: AppKey;
    appLink?: string;
    eBookLink?: string;
    worldOfMusicManual?: string;
    studentDiscountCoupon?: string;
    rechargeSubscriptionInfo?: RechargeSubscriptionInfo;
}

interface RechargeSubscriptionInfo {
    subscriptionId?: string;
    customerId?: string;
}

interface AppKey {
    id: string;
    key: string;
}

interface PaymentSource {
    apple?: PaymentMethodIAP | null;
    google?: PaymentMethodIAP | null;
    shopify?: PaymentMethod | null;
    productKey?: PaymentMethod | null;
}

interface Product extends DBDocument {
    id?: string;
    productId: string;
    name: string;
    appLink?: string;
    eBookLink?: string;
    variants: ProductVariant[];
}

interface ProductVariant {
    id: string;
    name: string;
    duration: number;
    price: string;
    source: VariantSource;
    maxSessions?: number;
}

interface VariantSource {
    shopify?: {
        productId: string;
        variantId: string;
    } | null;
    apple?: string | null;
    android?: string | null;
    productKey?: string | null;
}

class PaymentMethod {
    constructor(
        // public id: string,
        public purchaseDate: string,
        public expirationDate: string,
        public autoRenew: boolean,
        public isActive: boolean,
        public type: PaymentType
    ) {
    }
}

class PaymentMethodIAP extends PaymentMethod {
    purchaseId!: string;
    receipts?: Receipt[];
}

interface Receipt {
    // value: string;
    productId?: string;
    platform?: string;
    sandbox?: boolean;
    purchaseDate?: string;
    lastRenewalDate?: string;
    expirationDate?: string;
    cancelationReason?: string;
    renewalIntent?: string;
    isExpired?: boolean;
}

interface UserSetRequest extends User {
    oldPW?: string;
}

enum SplashUserType {
    none = 'none',
    student = 'student',
    teacher = 'teacher',
    school = 'school'
}

enum PaymentType {
    APPLE_IAP = 'apple_iap',
    ANDROID_IAP = 'android_iap',
    LICENSE_KEY = 'license_key',
    SHOPIFY = 'shopify'
}

enum ResultType {
    Unknown = 'Unknown',
    Ok = 'Ok',

    InvalidLogin = 'InvalidLogin',
    TooManyLogins = 'TooManyLogins',
    InvalidSession = 'InvalidSession',
    InvalidReceipt = 'InvalidReceipt',

    InvalidEmail = 'InvalidEmail',
    EmailAlreadyExists = 'EmailAlreadyExists',
    InvalidPassword = 'InvalidPassword'
}

interface ActiveLicense {
    productId: string;
    variantId: string;
    purchaseDate: number;
    expirationDate: number;
}

interface AuthData {
    email: string;
    password: string;
}

interface AuthCredentials extends AuthData {
    sessionType: string;
    device?: string;
}

interface Signup extends AuthCredentials {
    firstName: string;
    lastName: string;
    type: SplashUserType;
    newsletter: boolean;
    languageZone: string;
}

interface LicenseList {
    name: string;
    expiryDate: string | undefined;
    method: PaymentType | undefined;
    features: string;
}

interface User {
    id?: string;
    email: string;
    pendingEmail?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    languageZone?: string;
    licenses: License[];
    newsletter: boolean;
    type: SplashUserType;
    isAdmin?: boolean;
    isEmailVerified?: boolean
}

interface AuthResult {
    valid: boolean,
    isAdmin?: boolean
}

interface SelectOption {
    value: string,
    text: string
}

const DateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
}

interface ProductInfo {
    productName: string,
    variantName: string,
    productId: string,
    variantId: string
}

enum Bundle {
    harmonycity = 'harmonycity',
    flutemaster = 'flutemaster',
    rhythmicvillage = 'rhythmicvillage',
    worldofmusic = 'worldofmusic',
    themagicflute = 'themagicflute',
}

interface ErrorClass {
    error: string;
    errorMessage: string;
}

interface TableUserData {
    id: string;
    email: string;
    name: string;
    newsletter: boolean;
    type: SplashUserType;
}

interface VoucherList {
    voucherKey: string;
    productId: string;
    licenseId: string;
    user: string;
    edit?: string;
}

interface Voucher {
    key: string;
    licenseId?: string;
    variantId: string;
    productId: string;
    maxSessions?: number;
}

interface TokenList {
    token: string;
    productId: string;
    customerId?: number;
    customerEmail: string;
}

interface TokenAPI {
    id: string;
    token: string;
    productId: string;
    variantId: string;
    subscriptionInfo: {
        isActive: string;
        autoRenew: boolean;
        purchaseDate: string;
        expirationDate: string;
        intervalFrequency: string;
        intervalUnity: string;
        customerId: number;
        customerEmail: string;
    };
    updatedAt: string;
}

interface VoucherAPI {
    id: string;
    key: string;
    productId: string;
    variantId: string;
    licenseId: string;
    user: User;
    product: Product;
    createAt: string;
    updatedAt: string;
    userId: string;
}

interface ProductList {
    id: string;
    productId: string;
    variants: ProductVariant[]
    appLink: string;
    eBookLink: string;
    name: string;
    createdAt: string;
    datedAt: string;
}

interface UserList {
    id: string;
    email: string;
    oldEmail?: string;
    pendingEmail?: string;
    firstName?: string;
    lastName?: string;
    languageZone: string;
    licenses?: License[];
    type?: string;
    newsletter: boolean;
    isAdmin?: boolean;
    isEmailVerified: boolean;
    createdAt: string;
    updatedAt: string;
}

interface PopToastOption {
    message: string;
    variant?: string;
}

export {
    AuthCredentials,
    License,
    LicenseList,
    PaymentSource,
    PaymentMethod,
    PaymentMethodIAP,
    PaymentType,
    Receipt,
    Product,
    ProductVariant,
    VariantSource,
    ProductInfo,
    User,
    UserSetRequest,
    AuthResult,
    SplashUserType,
    Signup,
    SelectOption,
    ActiveLicense,
    LicenseListItem,
    Bundle,
    ErrorClass,
    TableUserData,
    VoucherList,
    ProductList,
    UserList,
    VoucherAPI,
    TokenList,
    TokenAPI,
    AuthData,
    DateOptions,
    ResultType,
    PopToastOption,
    Voucher
}