import thisVue from "@/main";
import { PopToastOption } from "@/types/typeDefinitions";

export function useToast() {

    const toastController = thisVue?.$bvToast

    const showToast = (toastName: string) => {
        thisVue.$bvToast.show(toastName)
    }

    const getTargetName = (eventTarget: string, targets: { deleteTarget: string, editTarget: string, createTarget: string }): string => {
        // target.value = eventTarget
        const { deleteTarget, editTarget, createTarget } = targets
        switch (eventTarget) {
            case 'delete':
                return deleteTarget;
            case 'save':
                return editTarget;
            case 'create':
                return createTarget;
            default:
                return editTarget;
        }
    }

    const popToast = (params: PopToastOption) => {
        const { message, variant } = params

        console.log(message, variant)

        thisVue.$bvToast.toast(message, {
            variant: variant,
            noCloseButton: true,
            autoHideDelay: 10000,
            toaster: 'b-toaster-top-center'
        })
    }

    return {
        showToast,
        getTargetName,
        popToast,
        toastController
    }
}