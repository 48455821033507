import Vue from 'vue'
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Login.vue";
import { ADMIN_KEY, TOKEN_KEY } from '@/composables/useApi';
// import SelectBundle from "@/views/VoucherLandingFlowWOM/SelectBundle.vue";
// import WelcomeSignup from "@/views/VoucherLandingFlowWOM/WelcomeSignup.vue";
// import AppSteps from "@/views/VoucherLandingFlowWOM/AppSteps.vue";
// import EndScreen from "@/views/VoucherLandingFlowWOM/EndScreen.vue";
// import WelcomePage from "@/views/VoucherLandingFlowWOM/WelcomePage.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/login',
        // meta: {
        //     noAuth: true
        // },
        // component: Login
    },
    {
        path: '/login',
        meta: {
            noAuth: true
        },
        name: 'Login',
        component: Login
    },
    {
        path: '/license',
        meta: {
            auth: true
        },
        name: 'License',
        component: () => import(/* webpackChunkName: "License" */ '@/views/License.vue')
    },
    {
        path: '/signup',
        meta: {
            noAuth: true
        },
        name: 'Signup',
        component: () => import(/* webpackChunkName: "Signup" */'@/views/Signup.vue')
    },
    {
        path: '/signupMail/:token',
        meta: {
            noAuth: true
        },
        name: 'SignupVerification',
        component: () => import(/* webpackChunkName: "VerifyEmail" */'@/views/VerifyEmail.vue')
    },
    {
        path: '/requestReset',
        meta: {
            noAuth: true
        },
        name: 'RequestRest',
        component: () => import(/* webpackChunkName: "RequestReset" */'@/views/RequestReset.vue')
    },
    {
        path: '/reset/:token',
        meta: {
            noAuth: true
        },
        name: 'ResetPW',
        component: () => import(/* webpackChunkName: "ResetPassword" */'@/views/ResetPassword.vue')
    },
    {
        path: '/user',
        meta: {
            auth: true
        },
        name: 'User',
        component: () => import(/* webpackChunkName: "User" */'@/views/User.vue')
    },
    {
        path: '/admin',
        meta: {
            noAuth: true
        },
        name: 'LoginAdmin',
        component: Login
    },
    {
        path: '/dashboard',
        meta: {
            admin: true
        },
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "AdminDashboard" */ '@/views/admin/AdminDashboard.vue'),
        redirect: '/userManagement',
        children: [
            {
                path: '/userManagement',
                meta: {
                    admin: true
                },
                name: 'UserManagement',
                component: () => import('@/views/admin/UserManagement.vue')
            },
            {
                path: '/productManagement',
                meta: {
                    admin: true
                },
                name: 'ProductManagement',
                component: () => import('@/views/admin/ProductManagement.vue')
            },
            {
                path: '/voucherManagement',
                meta: {
                    admin: true
                },
                name: 'VoucherManagement',
                component: () => import('@/views/admin/VoucherManagement.vue')
            },
            {
                path: '/voucherImport',
                meta: {
                    admin: true
                },
                name: 'VoucherImport',
                component: () => import('@/views/admin/VoucherImport.vue')
            },
            {
                path: '/shopifyTokenManagement',
                meta: {
                    admin: true
                },
                name: 'ShopifyManagement',
                component: () => import('@/views/admin/ShopifyTokenManagement.vue')
            }
        ]
    },
    {
        path: '/voucher',
        meta: {
            auth: true
        },
        name: 'Voucher',
        component: () => import(/* webpackChunkName: "Voucher" */'@/views/Voucher.vue')
    },
    {
        path: '/resendVerification/:email',
        meta: {
            noAuth: true
        },
        name: 'ResendVerification',
        component: () => import(/* webpackChunkName: "ResendVerification" */'@/views/ResendVerification.vue')
    },
    {
        path: '/welcome',
        redirect: '/welcomePage'
    },
    {
        path: '/welcomePage/:voucherCode',
        meta: {
            noAuth: true,
            welcome: true,
            next: 'VideoTutorial'
        },
        name: 'WelcomePageQR',
        component: () => import(/* webpackChunkName: "WelcomePage" */'@/views/VoucherLandingFlowWOM/WelcomePage.vue')
    },
    {
        path: '/welcomePage',
        meta: {
            welcome: true,
            next: 'VideoTutorial'
        },
        name: 'WelcomePage',
        component: () => import(/* webpackChunkName: "WelcomePage" */'@/views/VoucherLandingFlowWOM/WelcomePage.vue')
    },
    {
        path: '/videoTutorial',
        meta: {
            welcome: true,
            previous: 'WelcomePage',
            next: 'WelcomeSignup'
        },
        name: 'VideoTutorial',
        component: () => import(/* webpackChunkName: "VideoTutorial" */ '@/views/VoucherLandingFlowWOM/VideoTutorial.vue')
    },
    // {
    //     path: '/selectBundle',
    //     meta: {
    //         welcome: true,
    //         previous: 'WelcomePage',
    //         next: 'WelcomeSignup'
    //     },
    //     name: 'SelectBundle',
    //     component: () => import(/* webpackChunkName: "SelectBundle" */'@/views/VoucherLandingFlowWOM/SelectBundle.vue')
    // },
    {
        path: '/welcomeSignup',
        meta: {
            welcome: true,
            previous: 'VideoTutorial',
            next: 'WelcomeVoucher'
        },
        name: 'WelcomeSignup',
        component: () => import(/* webpackChunkName: "WelcomeSignup" */'@/views/VoucherLandingFlowWOM/WelcomeSignup.vue')
    },
    {
        path: '/redeem/:voucherCode',
        meta: {
            auth: true,
            welcome: true,
            next: 'PickUserType'
        },
        name: 'RedeemCode',
        component: () => import(/* webpackChunkName: "RedeemCode" */ '@/views/RedeemVoucher.vue')
    },
    {
        path: '/pickType',
        meta: {
            auth: true
        },
        name: 'PickUserType',
        component: () => import(/* webpackChunkName: "PickUserType" */ '@/views/VoucherLandingFlowWOM/PickUserType.vue')
    },
    {
        path: '/worldOfMusic',
        meta: {
            auth: true,
            previous: 'PickUserType'
        },
        name: 'WorldOfMusic',
        component: () => import(/* webpackChunkName: "WorldOfMusic" */ '@/views/VoucherLandingFlowWOM/WorldOfMusic.vue')
    },
    {
        path: '/welcomeVoucher',
        meta: {
            welcome: true,
            previous: 'WelcomeSignup',
            next: 'AppSteps',
            auth: true
        },
        name: 'WelcomeVoucher',
        component: () => import(/* webpackChunkName: "Voucher" */'@/views/Voucher.vue')
    },
    {
        path: '/appSteps',
        meta: {
            welcome: true,
            previous: 'WelcomeVoucher',
            next: 'EndScreen',
            auth: true
        },
        name: 'AppSteps',
        component: () => import(/* webpackChunkName: "AppSteps" */'@/views/VoucherLandingFlowWOM/AppSteps.vue')
    },
    {
        path: '/endScreen',
        meta: {
            welcome: true,
            previous: 'AppSteps',
            auth: true
        },
        name: 'EndScreen',
        component: () => import(/* webpackChunkName: "EndScreen" */'@/views/VoucherLandingFlowWOM/EndScreen.vue')
    },

    //The Magic Flute

    {
        path: '/welcome-tmf',
        name: 'WelcomePageTMF',
        component: () => import(/* webpackChunkName: "WelcomePageTMF" */'@/views/VoucherLandingFlowTMF/WelcomePageTMF.vue')
    },
    {
        path: '/welcomeSignup-tmf',
        name: 'WelcomeSignupTMF',
        component: () => import(/* webpackChunkName: "WelcomeSignupTMF" */'@/views/VoucherLandingFlowTMF/WelcomeSignupTMF.vue')
    },
    {
        path: '/voucher-tmf',
        redirect: '/welcomeVoucher-tmf'
    },
    {
        path: '/welcomeVoucher-tmf',
        meta: {
            auth: true
        },
        name: 'WelcomeVoucherTMF',
        component: () => import(/* webpackChunkName: "Voucher" */'@/views/VoucherLandingFlowTMF/VoucherTMF.vue')
    },
    {
        path: '/themagicflute',
        meta: {
            auth: true
        },
        name: 'TheMagicFlute',
        component: () => import(/* webpackChunkName: "TheMagicFlute" */'@/views/VoucherLandingFlowTMF/TheMagicFlute.vue')
    },

    // Wildcard route, catches all undefined
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log(to, from, next)
    const isAdmin = localStorage.getItem(ADMIN_KEY) === 'true'
    const user = localStorage.getItem(TOKEN_KEY)

    //Check if Page needs auth and User is Empty --> Reroute to Login
    if (to.meta?.auth && !user) {
        let nextPage = router.currentRoute.query.next
        let voucherCode = router.currentRoute.query.code
        if (to.name === 'RedeemCode') {
            nextPage = `RedeemCode`
            voucherCode = to.params.voucherCode
        }
        return next({ name: 'Login', query: { next: nextPage, code: voucherCode } })
    }

    //Check if Page needs Admin but user is not Admin --> Reroute to Admin Login
    if (to.meta?.admin && !isAdmin) return next({ name: 'LoginAdmin' })

    //Check if Target Rout is Login Admin but there is a User already logged in without Admin rights
    if (to.name === 'LoginAdmin' && user && !isAdmin) return next({ name: 'License' })

    //Check if Target Route is Login Admin but there is a logged in User with Admin rights --> Reroute to first Admin Page
    if (to.name === 'LoginAdmin' && isAdmin) return next({ name: 'AdminDashboard' })

    //Check if target Route is Login but there is a logged in User --> Reroute to first User Page
    if (to.name === 'Login' && user) return next({ name: 'License' })

    //Stay in voucher Landingpage flow:
    if (to.meta?.welcome && from.meta?.welcome) {
        if (from.meta?.next !== to.name && from.meta?.previous !== to.name && from.name !== to.name) return next(to.path)
    }

    //Block Option to go into middle of Voucher flow directly
    if (from.path === '/' && to.meta?.welcome && to.name !== 'WelcomePage' && from.name !== 'Voucher' && to.name !== 'RedeemCode')
        return next({ name: 'WelcomePage' })

    next()
})

export default router