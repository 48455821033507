import Vue from 'vue'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import './plugins/composition-api'
import App from './App.vue'
import router from "@/router";
import "./theme/global.css"
import './plugins/multi-language'
import i18n from "@/plugins/multi-language";
import './theme/poppins-font.css'

Vue.config.productionTip = false

const thisVue = new Vue({
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')

export default thisVue