const wofES = {
    download: '¡Genial! ¡Gracias! ¡Ahora descargue el World of Music App Launcher y comience su nueva experiencia!',
    downloadSubtitle: 'Recuerde que puede comprobar su licencia en cualquier momento. Inicie sesión y seleccione "licencias".',
    downloadButton: 'DESCARGAR AHORA',
    platform: 'Seleccione su plataforma',
    manual: 'Libro de registro de World of Music - Manual {extension}',
    voucher: 'Descuento exclusivo para tus alumnos - bono',
    appTitle: 'Lanzador del mundo de la música',
    discountVoucher: 'Bono con descuentos para alumnos',
    apps: 'Aplicaciones móviles disponibles',
    rank: '¡Hola y bienvenido al Aplicaciones World of Music! ¿Cuál es tu rango de astronauta?',
    student: 'Alumnos 2.0',
    teacher: 'Profesor 2.0',
    admin: 'Escuela Admin',
}

export default wofES