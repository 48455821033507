const wofFR = {
    download: 'Cool ! Merci ! Maintenant, télécharge le World of Music App Launcher et commence ta nouvelle expérience !',
    downloadSubtitle: 'N\'oubliez pas que vous pouvez vérifier votre licence à tout moment. Connectez-vous et sélectionnez "licences".',
    downloadButton: 'TÉLÉCHARGER MAINTENANT',
    platform: 'Sélectionnez votre plateforme',
    manual: 'World of Music Logbook - Manuel {extension}',
    voucher: 'Réduction exclusive pour vos étudiants - bon',
    appTitle: "Lanceur du monde de la musique",
    discountVoucher: 'Bon avec des réductions pour les étudiants',
    apps: 'Applications mobiles disponibles',
    rank: 'Salut et bienvenue dans applications World fo Music! Quel est ton grade d\'astronaute?',
    student: 'Élève 2.0',
    teacher: 'Professeur 2.0',
    admin: 'École Admin',
}

export default wofFR