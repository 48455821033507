const wofIT = {
    download: 'Forte! Grazie! Ora scaricate il World of Music App Launcher e iniziate la vostra nuova esperienza!',
    downloadSubtitle: 'Ricordate che potete controllare la vostra licenza in qualsiasi momento. Accedere e selezionare "licenze".',
    downloadButton: 'SCARICA ORA',
    platform: 'Scegli la tua piattaforma',
    manual: 'Diario del World of Music  - Manuale {extension}',
    voucher: 'Sconto esclusivo per i tuoi studenti - Voucher',
    appTitle: 'Mondo della musica - Launcher',
    discountVoucher: 'Buono sconto per studenti',
    apps: 'Applicazioni mobili disponibili',
    rank: 'Ciao e benvenuto nel Apps World of Music! Qual è il tuo grado di astronauta?',
    student: 'Alunni 2.0',
    teacher: 'Insegnante 2.0',
    admin: 'Scuola Admin',
}

export default wofIT