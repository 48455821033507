const tmfES = {
    issues: 'Si tiene problemas para usar la aplicación, visite nuestras preguntas frecuentes www.classplash.de/faq o comuníquese con support@classplash.com o llame al +49 721 619 020 20 (hablamos alemán, inglés o portugués).\n' +
        '\n' +
        'Somos un equipo pequeño pero dedicado y estaríamos muy contentos de recibir una revisión, por ejemplo en las tiendas de aplicaciones (Google Play, Apple) o en la tienda web donde compró su paquete (por ejemplo, Amazon, eBay, etc.).\n' +
        '\n' +
        '¡Muchas gracias por esto!\n' +
        '\n' +
        'Tu equipo Classplash\n',
    step: 'Paso',
    firstStep: 'Descargue la aplicación gratuita "La flauta mágica" e instálela en su dispositivo',
    secondStep: 'Seleccione su idioma en la aplicación y haga clic en el icono de la parte inferior derecha e inicie sesión con su cuenta de Classplash',
}

export default tmfES