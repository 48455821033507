



















































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { useAuth } from "@/api_connectors/auth";
import { AuthResult, SelectOption, SplashUserType } from "@/types/typeDefinitions";
import { translate } from "@/plugins/multi-language";
import LoginToast from "@/components/Toast/LoginToast.vue";
import { useToast } from "@/composables/useToast";
import router from "@/router";
import languageOptions from "@/composables/selectOptions";
import { WELCOME_REGISTER_KEY } from "@/assets/globalVariables";

export default defineComponent({
    name: "LoginInterface",
    components: { LoginToast },
    props: {
        mode: String,
        welcome: Boolean,
        white: Boolean,
        gold: Boolean,
    },
    setup(props, context) {
        const { signup, login, logout } = useAuth()
        const { showToast } = useToast()

        const email = ref('')
        const password = ref('')
        const passwordConfirm = ref('')
        const firstName = ref('')
        const lastName = ref('')
        const userType = ref<SplashUserType>(SplashUserType.none)
        const newsletter = ref(false)
        const agb = ref(false)
        const acceptedAGB = ref()
        const reason = ref('general')
        const requestSent = ref(false);
        //same regex as ajv validator of api
        const emailRegex = new RegExp('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$', 'i')
        const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})', 'g')

        const getLocalLanguage = (): string => {
            switch (navigator.language) {
                case 'da':
                    return 'da';
                case 'de':
                    return 'de-DE';
                case 'en':
                    return 'en-GB';
                case 'es':
                    return 'es-ES';
                case 'fr':
                    return 'fr-FR';
                case 'it':
                    return 'it-IT';
                case 'hr':
                    return 'hr';
                case 'hu':
                    return 'hu';
                case 'nl':
                    return 'nl';
                case 'pt':
                    return 'pt-PT';
                case 'ru':
                    return 'ru';
                case 'sl':
                    return 'sl';
                case 'zh':
                    return 'zh-CN';
                default:
                    return 'en-GB';
            }
        }

        const userLanguage = ref(getLocalLanguage())

        const signUpUser = async () => {
            requestSent.value = true;
            if (!passwordValid.value) return;
            if (!passwordSame.value) return;
            if (!emailValid.value) return;
            if (!agb.value) return acceptedAGB.value = false;

            const response = await signup({
                email: email.value,
                password: password.value,
                firstName: firstName.value,
                lastName: lastName.value,
                type: userType.value,
                newsletter: newsletter.value,
                sessionType: 'WEB',
                languageZone: userLanguage.value
            })
            if (!response) return
            if (props.welcome) localStorage.setItem(WELCOME_REGISTER_KEY, 'true')

            await login({
                email: email.value,
                password: password.value,
                sessionType: 'WEB'
            })

            return context.emit('signed')
        }

        const loginUser = async () => {
            // const device = navigator.userAgent
            // console.log(device)
            const response: AuthResult | undefined = await login({
                email: email.value,
                password: password.value,
                sessionType: 'WEB'
            })
            if (!response) return showToast('login-toast')

            if (!response.valid) return router.push(`/resendVerification/${email.value}`)

            const isAdminPortal = router.currentRoute.path === '/admin'
            const isWelcome = router.currentRoute.path === '/welcomeSignup'
            const isWelcomeTmf = router.currentRoute.path === '/welcomeSignup-tmf'

            if (response.valid && isWelcome) return router.push('/welcomeVoucher')
            if (response.valid && isWelcomeTmf) return router.push('/welcomeVoucher-tmf')
            if (response.valid && !isAdminPortal) {
                if (router.currentRoute.query.next === 'RedeemCode') return await router.push({
                    name: 'RedeemCode',
                    params: { voucherCode: router.currentRoute.query.code as string }
                })

                return await router.push('/license')
            }
            if (response.valid && response.isAdmin && isAdminPortal) return await router.push('/dashboard')
            if (response.valid && !response.isAdmin && isAdminPortal) await logout()
        }

        const typeOptions = computed(() => {
            const typeArray = Array<SelectOption>()
            let key: keyof typeof SplashUserType
            for (key in SplashUserType) {
                typeArray.push({ value: key, text: translate('types.' + key).toString() })
            }
            return typeArray;
        })

        const emailValid = computed(() => {
            if (props.mode === 'login') return null;
            if (email.value === '') return null;

            return emailRegex.test(email.value)
        })

        const passwordValid = computed(() => {
            if (props.mode !== 'signup') return null;
            if (password.value === '' && !requestSent.value) return null;

            return passwordRegex.test(password.value);
        })

        const passwordSame = computed(() => {
            if (password.value === '' || passwordConfirm.value === '' && !requestSent.value) return null

            return password.value === passwordConfirm.value
        })

        const changeToSignup = () => {
            if (router.currentRoute.path === '/welcomeSignup') return context.emit('change-mode', 'signup')
            if (router.currentRoute.path === '/welcomeSignup-tmf') return context.emit('change-mode', 'signup')

            router.push({
                name: 'Signup',
                query: { next: router.currentRoute.query.next, code: router.currentRoute.query.code }
            })
        }

        const changeToRequestReset = () => {
            if (router.currentRoute.path === '/welcomeSignup') return context.emit('change-mode', 'reset')

            router.push('/requestReset')
        }

        return {
            signUpUser,
            loginUser,
            changeToSignup,
            changeToRequestReset,
            typeOptions,
            password,
            passwordConfirm,
            email,
            userType,
            newsletter,
            agb,
            emailValid,
            passwordValid,
            passwordSame,
            firstName,
            lastName,
            acceptedAGB,
            reason,
            userLanguage,
            languageOptions
        }
    }
})
